import React from "react";
import withRouter from "@/app/withRouter";
import Banner from "@/components/Banner";
import Header from "@/layouts/Header";
import { getApi } from "@/app/Api";
import {
    formatInteger,
    getImage,
    getNumeric,
    getStorage,
    handleErrors,
    notifySuccess,
    pushAlert,
    send,
} from "@/app/Func";
import Input from "@/components/Input";

class Home extends React.Component {
    constructor(props) {
        super(props);
        window.Home = this;
        this.state = {
            idols: [],
            idol_id: undefined,
            point: undefined,
            name: undefined,
            description: undefined,
            modalOpen: false,
        };
    }
    mounted = false;
    componentDidMount = () => {
        this.mounted = true;
        this.initData();
    };
    componentWillUnmount() {
        this.mounted = false;
    }
    initData = () => {
        send(
            getApi("idol", "path"),
            getApi("idol", "method"),
            undefined,
            (res) => {
                if (res.data && res.data.data) {
                    let data = res.data.data;
                    this.mounted &&
                        data.idols &&
                        this.setState({
                            ...this.state,
                            idols: data.idols,
                        });
                }
            },
            (res) => {
                this.mounted &&
                    this.setState({
                        ...this.state,
                        submited: true,
                    });
                console.clear();
            }
        );
    };
    hideModal = () => {
        this.mounted &&
            this.setState({
                ...this.state,
                idol_id: undefined,
                point: undefined,
                name: undefined,
                description: undefined,
                modalOpen: false,
            });
    };
    showModal = (idol_id, name, description) => {
        this.mounted &&
            this.setState({
                ...this.state,
                idol_id: idol_id,
                name: name,
                description: description,
                modalOpen: true,
            });
    };
    setInput = (event) => {
        let value = event.target.value ? parseInt(event.target.value) : 0;
        this.mounted &&
            this.setState({
                ...this.state,
                point: value,
            });
        event.target.value = value;
        return value;
    };
    order = (event) => {
        event.preventDefault();
        if (this.state.point) {
            if (parseInt(this.state.point) <= getStorage("point")) {
                send(
                    getApi("order", "path"),
                    getApi("order", "method"),
                    {
                        idol_id: this.state.idol_id,
                        point: this.state.point,
                    },
                    (res) => {
                        if (res.data.code === 200) {
                            res.data.message && notifySuccess(res.data.message);
                            this.mounted &&
                                this.setState({
                                    ...this.state,
                                    idol_id: undefined,
                                    point: undefined,
                                    name: undefined,
                                    description: undefined,
                                    modalOpen: false,
                                    idols: res.data.data.idols,
                                });
                        } else {
                            handleErrors(res, this);
                        }
                    },
                    (res) => {
                        handleErrors(res, this);
                    }
                );
            } else {
                pushAlert("Bạn không đủ điểm", "error");
            }
        } else {
            pushAlert("Vui lòng nhập số điểm", "error");
        }
    };
    render() {
        return (
            <div className="home">
                <Header></Header>
                <Banner
                    className="home__banner"
                    array={[
                        {
                            url: "/images/banners/2d5cc8bc9f930ce292c464e929ea31fb.webp",
                        },
                        {
                            url: "/images/banners/4aa428e316cde8219f7785210348cbf3.webp",
                        },
                        {
                            url: "/images/banners/5b57e6c61848cfed2fa19cd31b293898.webp",
                        },
                        {
                            url: "/images/banners/8abddc61f30760c7f1479a2d3e624b96.webp",
                        },
                        {
                            url: "/images/banners/52fc0f8e15e949a37f1c9b9de985e804.webp",
                        },
                        {
                            url: "/images/banners/0924571ca7128eaa17ce2835e47cb132.webp",
                        },
                        {
                            url: "/images/banners/b6d1ed0d09b63c3006e56f7e7839014e.webp",
                        },
                        {
                            url: "/images/banners/ca39302be7648c3e59e3071558d4c4f9.webp",
                        },
                        {
                            url: "/images/banners/f19ad7841238e403df36cc7a9c536837.webp",
                        },
                    ]}
                ></Banner>
                {/* <div className="hotline">
                    <i className="fas fa-phone"></i>Hotline:
                    <a href="tel:1900252386">1900.252.386</a>
                </div> */}
                <div className="title">Bảng xếp hạng</div>
                <div className="idols">
                    {this.state.idols.map((item, index) => {
                        return (
                            <div className="idol" key={"idol_" + index}>
                                <div className="idol__image">
                                    <img
                                        src={getImage(item.image)}
                                        alt={item.name}
                                    />
                                </div>
                                <div className="idol__info">
                                    <h3>{item.name}</h3>
                                    <div className="idol__voted">
                                        <i className="fas fa-heart"></i>
                                        <span>{formatInteger(item.vote)}</span>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn__medium btn__warning"
                                        onClick={() =>
                                            this.showModal(
                                                item.id,
                                                item.name,
                                                item.description
                                            )
                                        }
                                    >
                                        Bỏ phiếu cho{" "}
                                        {item.gender === 1 ? "Anh ấy" : "Cô ấy"}
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div
                    className={
                        "idol__modal" +
                        (this.state.modalOpen ? " idol__modal-active" : "")
                    }
                >
                    <div
                        className="idol__modal-background"
                        onClick={() => this.hideModal()}
                    ></div>
                    <div className="idol__modal-main">
                        <i
                            className="fas fa-times idol__modal-close"
                            onClick={() => this.hideModal()}
                        ></i>
                        <div className="idol__modal-head">
                            {this.state.name}
                        </div>
                        <div className="idol__modal-body">
                            <Input
                                type="number"
                                onInput={this.setInput}
                                name="point"
                                value={this.state.point}
                                pattern="[0-9]*"
                                inputMode="numeric"
                                placeholder="Vui lòng nhập số phiếu bầu"
                            ></Input>
                        </div>
                        <div className="idol__modal-footer">
                            <button
                                className="btn btn__big btn__warning idol__modal-confirm"
                                type="button"
                                disabled={
                                    this.state.point &&
                                    getNumeric(this.state.point) <= 0
                                }
                                readOnly={
                                    this.state.point &&
                                    getNumeric(this.state.point) <= 0
                                }
                                onClick={this.order}
                            >
                                Bỏ phiếu
                            </button>
                            <div className="idol__modal-description">
                                {this.state.description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Home);
