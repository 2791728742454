import { getApi } from "@/app/Api";
import { formatBalance, formatDate, send } from "@/app/Func";
import NoData from "@/components/NoData";
import Header from "@/layouts/Header";
import React from "react";

class WithdrawHistory extends React.Component {
    constructor(props) {
        super(props);
        window.WithdrawHistory = this;
        this.state = {};
    }
    mounted = false;
    componentDidMount = () => {
        this.mounted = true;
        this.initData();
    };
    componentWillUnmount() {
        this.mounted = false;
    }
    initData = () => {
        send(
            getApi("withdraw.history", "path"),
            getApi("withdraw.history", "method"),
            null,
            (res) => {
                if (res.data && res.data.data) {
                    let data = res.data.data;
                    this.mounted &&
                        this.setState({
                            ...this.state,
                            withdraws: data,
                        });
                }
            },
            (res) => {
                console.clear();
            }
        );
    };
    render() {
        return (
            <div className="withdraw">
                <Header></Header>
                {this.state.withdraws && this.state.withdraws.length ? (
                    <div className="withdraw-history__list">
                        {this.state.withdraws.map((item) => {
                            return (
                                <div
                                    key={item.id}
                                    className={
                                        "withdraw-history__item withdraw-history__item-" +
                                        (parseInt(item.status) === 1
                                            ? "success"
                                            : parseInt(item.status) === 2
                                            ? "danger"
                                            : "warning")
                                    }
                                >
                                    <div className="withdraw-history__item-head">
                                        <strong>Yêu cầu rút tiền</strong>
                                        <strong>
                                            {formatDate(item.created_at)}
                                        </strong>
                                    </div>
                                    <div className="withdraw-history__item-row">
                                        <strong>Số tiền rút: </strong>
                                        <strong className="color__danger">
                                            {formatBalance(item.balance)}
                                        </strong>
                                    </div>
                                    <div className="withdraw-history__item-row">
                                        <strong>Trạng thái: </strong>
                                        {parseInt(item.status) === 1 ? (
                                            <label className="label__success">
                                                {item.status_text}
                                            </label>
                                        ) : parseInt(item.status) === 2 ? (
                                            <label className="label__danger">
                                                {item.status_text}
                                            </label>
                                        ) : (
                                            <label className="label__warning">
                                                {item.status_text}
                                            </label>
                                        )}
                                    </div>

                                    {parseInt(item.status) === 1 && (
                                        <div className="withdraw-history__item-row">
                                            <strong>Nội dung: </strong>
                                            <span>
                                                {parseInt(item.status) === 1
                                                    ? item.note
                                                    : parseInt(item.status) ===
                                                      2
                                                    ? item.note
                                                    : item.status_text}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <NoData></NoData>
                )}
            </div>
        );
    }
}

export default WithdrawHistory;
