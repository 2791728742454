import {
    getNumeric,
    toAlphabet,
    toAlphabetVN,
    toAlphadash,
    toAlphadashVN,
    toPhoneVN,
    toSlug,
} from "@/app/Func";
import React from "react";

class Textarea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    mounted = false;
    componentDidMount = () => {
        this.mounted = true;
    };
    componentWillUnmount() {
        this.mounted = false;
    }
    onFormat = (event) => {
        let string = event.target.value,
            result = string,
            space = this.props.nospace ? false : true;
        switch (this.props.format) {
            case "Alphabet":
                event.target.value = toAlphabet(string, space, null);
                break;
            case "alphabet":
                event.target.value = toAlphabet(string, space, 1);
                break;
            case "ALPHABET":
                event.target.value = toAlphabet(string, space, 2);
                break;
            case "Alphadash":
                event.target.value = toAlphadash(string, space, null);
                break;
            case "alphadash":
                event.target.value = toAlphadash(string, space, 1);
                break;
            case "ALPHADASH":
                event.target.value = toAlphadash(string, space, 2);
                break;
            case "AlphabetVN":
                event.target.value = toAlphabetVN(string, space, null);
                break;
            case "alphabetvn":
                event.target.value = toAlphabetVN(string, space, 1);
                break;
            case "ALPHABETVN":
                event.target.value = toAlphabetVN(string, space, 2);
                break;
            case "AlphadashVN":
                event.target.value = toAlphadashVN(string, space, null);
                break;
            case "alphadashvn":
                event.target.value = toAlphadashVN(string, space, 1);
                break;
            case "ALPHADASHVN":
                event.target.value = toAlphadashVN(string, space, 2);
                break;
            case "Slug":
                event.target.value = toSlug(string);
                break;
            case "slug":
                event.target.value = toSlug(string, 1);
                break;
            case "SLUG":
                event.target.value = toSlug(string, 2);
                break;
            case "phonevn":
                event.target.value = toPhoneVN(string, this.props.max || 10);
                break;
            default:
                break;
        }
        this.cutString(event);
        this.props.onInput(event, result);
    };
    onBlur = (event) => {
        if (this.props.format) {
            let value = event.target.value;
            value = value.toString();
            switch (this.props.format) {
                case "slug":
                    event.target.value = value.replace(/^-+|-+$/g, "");
                    break;
                case "numeric":
                    let minNumeric = getNumeric(this.props.min);
                    value = getNumeric(value);
                    !value && (event.target.value = minNumeric);
                    if (value < minNumeric && event && event.target) {
                        event.target.value = minNumeric;
                    }
                    break;
                case "integer":
                    let minInteger = getNumeric(this.props.min);
                    value = getNumeric(value);
                    !value && (event.target.value = minInteger);
                    if (value < minInteger && event && event.target) {
                        event.target.value = minInteger;
                    }
                    break;
                case "float":
                    let minFloat = getNumeric(this.props.min);
                    value = getNumeric(value);
                    !value && (event.target.value = minFloat);
                    if (value < minFloat && event && event.target) {
                        event.target.value = minFloat;
                    }
                    break;
                default:
                    break;
            }
        }
        this.onFormat(event);
    };
    cutString = (event) => {
        if (this.props.max) {
            let string = event.target.value;
            let max = parseInt(this.props.max);
            if (max > 0) {
                event.target.value = string.toString().substring(0, max);
            }
        }
    };
    render() {
        return (
            <div className="textarea">
                <textarea
                    {...this.props}
                    onInput={this.onFormat}
                    onBlur={this.onBlur}
                    onFocus={this.onFormat}
                    onChange={this.onFormat}
                    value={this.props.value || ""}
                />
                {this.props.max && (
                    <span>
                        {this.props.value && this.props.value.length
                            ? this.props.value.length
                            : 0}
                        /{parseInt(this.props.max)}
                    </span>
                )}
            </div>
        );
    }
}

export default Textarea;
