import React from "react";
import methods from "validator";

export const RenderError = ({ errors, field }) => {
    return errors[field] ? (
        <div className="error">{errors[field]}</div>
    ) : (
        <React.Fragment></React.Fragment>
    );
};

class Validator {
    constructor(rules) {
        this.rules = rules;
        this.init();
    }

    init() {
        this.isValid = true;
        this.errors = {};
    }

    valid(state) {
        this.init();
        this.rules.forEach((rule) => {
            if (this.errors[rule.field]) return;
            const fieldValue = state[rule.field] || "";
            const args = rule.args || [];
            const validationMethod =
                typeof rule.method === "string"
                    ? methods[rule.method]
                    : rule.method;
            if (
                validationMethod(fieldValue.toString(), ...args, state) !==
                rule.validWhen
            ) {
                this.errors[rule.field] = rule.message;
                this.isValid = false;
            }
        });
        return this.errors;
    }
}
export default Validator;
