import React from "react";
import withRouter from "@/app/withRouter";
import { Outlet } from "react-router";
import { Slide, ToastContainer } from "react-toastify";
import { io } from "socket.io-client";
import Socket from "./Socket";
import { env, setStorage } from "@/app/Func";

class Master extends React.Component {
    constructor(props) {
        super(props);
        window.Master = this;
        this.state = {};
        if (env("REACT_APP_SOCKET_OPEN")) {
            let socketUrl = env("REACT_APP_MODE_PRODUCTION")
                ? env("REACT_APP_SOCKET_PRODUCTION_URL")
                : env("REACT_APP_SOCKET_LOCAL_URL");
            this.socket = io(socketUrl, { autoConnect: false });
        }
    }
    mounted = false;
    componentDidMount() {
        window.addEventListener("focus", this.onFocus);
        window.addEventListener("blur", this.onBlur);
        window.addEventListener("visibilitychange", this.onVisibilityChange);
        setStorage({ sending: "false" });
        if (env("REACT_APP_SOCKET_OPEN")) {
            this.socket.open();
        }
        this.mounted = true;
    }
    componentWillUnmount = () => {
        window.removeEventListener("focus", this.onFocus);
        window.removeEventListener("blur", this.onBlur);
        window.removeEventListener("visibilitychange", this.onVisibilityChange);
        if (env("REACT_APP_SOCKET_OPEN")) {
            this.socket.close();
        }
        this.mounted = false;
    };
    onBlur = () => {};
    onFocus = () => {};
    onVisibilityChange = () => {};
    render() {
        return (
            <React.Fragment>
                <section className={window.App.state.isLogin ? "main" : ""}>
                    <Outlet></Outlet>
                </section>
                <Socket></Socket>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover={false}
                    transition={Slide}
                    limit={3}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(Master);
