import { getApi } from "@/app/Api";
import {
    confirmAlert,
    formatBalance,
    getNumeric,
    getRoute,
    getStorage,
    handleErrors,
    notifySuccess,
    send,
    setInput,
} from "@/app/Func";
import Input from "@/components/Input";
import Notify from "@/components/Notify";
import Header from "@/layouts/Header";
import React from "react";
import { Navigate } from "react-router";

const betMin = 0;
class Withdraw extends React.Component {
    constructor(props) {
        super(props);
        window.Withdraw = this;
        this.state = {
            submited: false,
            withdraw: undefined,
            id: getStorage("id"),
            bank_id: getStorage("bank_id"),
            bank_number: getStorage("bank_number"),
            bank_user: getStorage("bank_user"),
            bank_name: getStorage("bank_name"),
            check_bank: true,
            balance: getStorage("balance"),
        };
    }
    mounted = false;
    componentDidMount = () => {
        this.mounted = true;
        this.initData();
    };
    componentWillUnmount() {
        this.mounted = false;
    }
    initData = () => {
        this.state.id &&
            send(
                getApi("info", "path"),
                getApi("info", "method"),
                undefined,
                (res) => {
                    if (res.data && res.data.data) {
                        let data = res.data.data;
                        this.mounted &&
                            this.setState({
                                ...this.state,
                                id: data.user.id,
                                bank_id: data.user.bank_id,
                                bank_number: data.user.bank_number,
                                bank_user: data.user.bank_user,
                                bank_name: data.user.bank_name,
                                check_bank: data.user.check_bank,
                                balance: data.user.balance,
                            });
                    }
                },
                (res) => {}
            );
    };
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.withdraw && getNumeric(this.state.withdraw) > 0) {
            confirmAlert(
                "Xác nhận gửi yêu cầu rút tiền ? <br> *Chú ý: để yêu cầu rút tiền được duyệt, qúy khách vui lòng duy trì số dư tài khoản tối thiểu bằng số tiền yêu cầu rút và không gửi yêu cầu liên tiếp nhiều lần. <br> Nếu phát hiện hành vi xấu sẽ bị khóa tài khoản vĩnh viễn. ",
                (confirmed) => {
                    if (confirmed) {
                        send(
                            getApi("withdraw", "path", { user: this.state.id }),
                            getApi("withdraw", "method"),
                            {
                                balance: this.state.withdraw,
                            },
                            (res) => {
                                if (res.data.code === 200) {
                                    res.data.message &&
                                        notifySuccess(res.data.message);
                                    this.mounted &&
                                        this.setState({
                                            ...this.state,
                                            submited: true,
                                        });
                                } else {
                                    handleErrors(res, this);
                                }
                            },
                            (res) => {
                                handleErrors(res, this);
                            }
                        );
                    }
                }
            );
        }
    };
    render() {
        return this.state.submited ? (
            <Navigate to={getRoute("home")}></Navigate>
        ) : (
            <div className="withdraw">
                <Header></Header>
                <form onSubmit={this.handleSubmit} id="form" className="form">
                    <div className="form__item">
                        <div className="form__item-title">
                            Nhập số tiền rút
                            <span className="form__item-required">*</span>
                            <span>
                                (Tối đa: {formatBalance(this.state.balance)})
                            </span>
                        </div>
                        <div className="form__item-field">
                            <Input
                                format="integer"
                                min={
                                    betMin > this.state.balance
                                        ? this.state.balance
                                        : betMin
                                }
                                max={
                                    this.state.balance
                                        ? this.state.balance
                                        : false
                                }
                                onInput={(event) => setInput(event, this)}
                                suffix={" đ"}
                                name="withdraw"
                                placeholder="Nhập số tiền..."
                                value={this.state.withdraw}
                                disabled={
                                    this.state.check_bank && this.state.balance
                                        ? false
                                        : true
                                }
                                readOnly={
                                    this.state.check_bank && this.state.balance
                                        ? false
                                        : true
                                }
                            ></Input>
                            <i
                                onClick={() =>
                                    this.state.withdraw &&
                                    this.mounted &&
                                    this.setState({
                                        ...this.state,
                                        withdraw: undefined,
                                    })
                                }
                                className={
                                    this.state.withdraw &&
                                    getNumeric(this.state.withdraw) > 0
                                        ? "fas fa-eraser"
                                        : "fas fa-keyboard"
                                }
                            ></i>
                        </div>
                    </div>
                    {this.state.check_bank ? (
                        <React.Fragment>
                            <div className="form__item">
                                <div className="bank-info">
                                    <div className="bank-info__title fw__bold">
                                        Tài khoản ngân hàng thụ hưởng:
                                    </div>
                                    <div className="bank-info__item">
                                        <p>Ngân hàng:</p>
                                        <strong>{this.state.bank_name}</strong>
                                    </div>
                                    <div className="bank-info__item">
                                        <p>Chủ tài khoản:</p>
                                        <strong>{this.state.bank_user}</strong>
                                    </div>
                                    <div className="bank-info__item">
                                        <p>Số tài khoản:</p>
                                        <strong>
                                            {this.state.bank_number}
                                        </strong>
                                    </div>
                                    <div className="bank-info__item">
                                        <p>Số tiền rút:</p>
                                        <strong>
                                            {formatBalance(this.state.withdraw)}
                                        </strong>
                                    </div>
                                    <div className="bank-info__note">
                                        *Sau khi yêu cầu rút được khởi tạo, tài
                                        vụ sẽ kiểm tra và chuyển khoản tiền vào
                                        tài khoản ngân hàng của quý khách trong
                                        vòng 24h làm việc. <br />
                                        *Chi tiết vui lòng liên hệ bộ phận hỗ
                                        trợ.
                                    </div>
                                </div>
                            </div>
                            <div className="form__item">
                                <button
                                    className="btn btn__big btn__success"
                                    type="submit"
                                    disabled={
                                        getNumeric(this.state.withdraw) > 0 &&
                                        this.state.check_bank &&
                                        this.state.balance
                                            ? false
                                            : true
                                    }
                                    readOnly={
                                        getNumeric(this.state.withdraw) > 0 &&
                                        this.state.check_bank &&
                                        this.state.balance
                                            ? false
                                            : true
                                    }
                                >
                                    Gửi yêu cầu rút tiền
                                </button>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Notify
                                className="notify__danger"
                                style={{
                                    marginBottom: "0px",
                                }}
                            >
                                Quý khách chưa cập nhật đầy đủ thông tin ngân
                                hàng ! <br />
                                Vui lòng cập nhật đẩy đủ để tiến hành rút tiền.
                                <br />
                            </Notify>
                        </React.Fragment>
                    )}
                </form>
            </div>
        );
    }
}

export default Withdraw;
