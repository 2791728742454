import React from "react";
import Slider from "react-slick";

const settingsDefault = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: false,
    prevArrow: null,
    nextArrow: null,
    arrows: false,
};
class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        let settings = this.props.settings
            ? this.props.settings
            : settingsDefault;
        return (
            this.props.array && (
                <div
                    className={
                        "banner " +
                        (this.props.className ? this.props.className : "")
                    }
                >
                    <div className="banner__container">
                        <Slider {...settings}>
                            {this.props.array.map((item, index) => (
                                <img
                                    className="banner__item"
                                    key={index}
                                    src={item.url}
                                    alt={item.alt}
                                />
                            ))}
                        </Slider>
                    </div>
                </div>
            )
        );
    }
}

export default Banner;
