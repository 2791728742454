import { getApi } from "@/app/Api";
import {
    formatDate,
    formatInteger,
    getImage,
    getRoute,
    send,
} from "@/app/Func";
import { trans } from "@/app/Lang";
import NoData from "@/components/NoData";
import Header from "@/layouts/Header";
import React from "react";
import { NavLink } from "react-router-dom";

class OrderHistory extends React.Component {
    constructor(props) {
        super(props);
        window.OrderHistory = this;
        this.state = {};
    }
    mounted = false;
    componentDidMount = () => {
        this.mounted = true;
        this.initData();
    };
    componentWillUnmount() {
        this.mounted = false;
    }
    initData = () => {
        send(
            getApi("order.history", "path"),
            getApi("order.history", "method"),
            null,
            (res) => {
                if (res.data && res.data.data) {
                    let data = res.data.data;
                    this.mounted &&
                        this.setState({
                            ...this.state,
                            orders: data,
                        });
                }
            },
            (res) => {
                console.clear();
            }
        );
    };
    render() {
        return (
            <div className="order">
                <Header></Header>
                {this.state.orders && this.state.orders.length ? (
                    <div className="order__container">
                        {this.state.orders.map((item) => {
                            return (
                                <div key={item.id} className={"order__item"}>
                                    <div className="order__left">
                                        <div className="order__image">
                                            <img
                                                src={getImage(item.idol_image)}
                                                alt={item.idol_name}
                                            />
                                        </div>
                                    </div>

                                    <div className="order__right">
                                        <div className="order__info">
                                            <span>
                                                {trans("Đã bình chọn ")}
                                            </span>
                                            <small>
                                                {formatInteger(item.point)}{" "}
                                                <i className="fas fa-heart"></i>
                                            </small>
                                            <span>
                                                lúc:{" "}
                                                {formatDate(item.created_at)}
                                            </span>
                                            <span>{trans(" cho ")}</span>
                                            <strong>{item.idol_name}</strong>
                                        </div>
                                        <NavLink
                                            to={getRoute("home")}
                                            className="btn btn__medium btn__warning order__button"
                                        >
                                            {trans("Tiếp tục bình chọn cho")}{" "}
                                            {item.idol_gender === 1
                                                ? "Anh ấy"
                                                : "Cô ấy"}
                                            <i className="far fa-angles-right"></i>
                                        </NavLink>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <NoData></NoData>
                )}
            </div>
        );
    }
}

export default OrderHistory;
