import { env, injectParams } from "./Func";

const Api = {
    login: { path: "/user/login", method: "POST" },
    logout: { path: "/user/logout", method: "POST" },
    register: { path: "/user/register", method: "POST" },
    check: { path: "/user/check", method: "POST" },
    idol: { path: "/user/idol", method: "POST" },
    info: { path: "/user/info", method: "POST" },
    update: { path: "/user/update", method: "POST" },
    order: {
        path: "/user/order",
        method: "POST",
        history: { path: "/user/order/history", method: "POST" },
    },
    withdraw: {
        path: "/user/withdraw",
        method: "POST",
        history: { path: "/user/withdraw/history", method: "POST" },
    },
};
export function getApi(route, type = "path", params, debug = false) {
    if (route && ["path", "method"].includes(type)) {
        route += "." + type;
        route = route.split(".");
        let prefix =
            env("REACT_APP_MODE_PRODUCTION") === true
                ? env("REACT_APP_API_PRODUCTION_URL")
                : env("REACT_APP_API_LOCAL_URL");
        let result = route.reduce((obj, key) => obj[key], Api);
        result = type === "path" ? prefix + result : result;
        if (result && typeof params === "object" && params !== null) {
            result = injectParams(result, params);
        }
        return result;
    }
    return null;
}

export default Api;
