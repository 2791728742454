import { getStorage } from "./Func";

export const Lang = [
    {
        value: "vi",
        label: (
            <div className="option__image">
                <img src={"/images/flags/vn.png"} alt="Tiếng việt" />
                vi
            </div>
        ),
    },
    {
        value: "en",
        label: (
            <div className="option__image">
                <img src={"/images/flags/gb.png"} alt="English" />
                en
            </div>
        ),
    },
    {
        value: "zh_CN",
        label: (
            <div className="option__image">
                <img src={"/images/flags/cn.png"} alt="中国人" />
                zh_CN
            </div>
        ),
    },
];
export function trans(key) {
    let langData = [];
    if (localStorage.hasOwnProperty("langData")) {
        langData = JSON.parse(localStorage.getItem("langData"));
    }
    if (!langData.includes(key)) {
        langData.push(key);
    }
    localStorage.setItem("langData", JSON.stringify(langData));
    let lang = getStorage("lang");
    lang = lang ? lang : "vn";
    if (Translations[key] && Translations[key][lang]) {
        return Translations[key][lang];
    } else {
        return key;
    }
}
const Translations = {
    "Chào mừng bạn đến với hệ thống quản trị !": {
        vi: "Chào mừng bạn đến với hệ thống quản trị !",
        en: "Welcome to the administration system !",
        zh_CN: "欢迎使用管理系统！",
    },
    STT: {
        vi: "STT",
        zh_CN: "STT",
    },
    "Tên đăng nhập": {
        vi: "Tên đăng nhập",
        zh_CN: "用户名",
    },
    "Trò chơi": {
        vi: "Trò chơi",
        zh_CN: "游戏",
    },
    "Tiền cược": {
        vi: "Tiền cược",
        zh_CN: "赌注",
    },
    "Giá trị cược": {
        vi: "Giá trị cược",
        zh_CN: "投注价值",
    },
    "Thời gian cược": {
        vi: "Thời gian cược",
        zh_CN: "下注时间",
    },
    "KQ trò chơi": {
        vi: "KQ trò chơi",
        zh_CN: "游戏结果",
    },
    "Kết quả": {
        vi: "Kết quả",
        zh_CN: "结果",
    },
    "Tình trạng": {
        vi: "Tình trạng",
        zh_CN: "地位",
    },
    "Trả thưởng": {
        vi: "Trả thưởng",
        zh_CN: "奖励",
    },
    "Tác vụ": {
        vi: "Tác vụ",
        zh_CN: "行动",
    },
    "Kỳ (No.)": {
        vi: "Kỳ (No.)",
        zh_CN: "基（号）",
    },
    "Kỳ (No.):": {
        vi: "Kỳ (No.):",
        zh_CN: "基（号）:",
    },
    "Tổng cược": {
        vi: "Tổng cược",
        zh_CN: "总赌注",
    },
    "Tổng thắng": {
        vi: "Tổng thắng",
        zh_CN: "总赢",
    },
    "T/gian": {
        vi: "T/gian",
        zh_CN: "时间",
    },
    "Trạng thái": {
        vi: "Trạng thái",
        zh_CN: "地位",
    },
    "Set KQ": {
        vi: "Set KQ",
        zh_CN: "结果集",
    },
    "Mã giới thiệu": {
        vi: "Mã giới thiệu",
        zh_CN: "推荐码",
    },
    "Số cấp dưới F1": {
        vi: "Số cấp dưới F1",
        zh_CN: "下属人数 F1",
    },
    "Số cấp dưới F2": {
        vi: "Số cấp dưới F2",
        zh_CN: "下属人数 F2",
    },
    "Số cấp dưới F3": {
        vi: "Số cấp dưới F3",
        zh_CN: "下属人数 F3",
    },
    "Ngày tạo": {
        vi: "Ngày tạo",
        zh_CN: "创建日期",
    },
    "Họ tên tối đa 32 ký tự.": {
        vi: "Họ tên tối đa 32 ký tự.",
        zh_CN: "名称最多 32 个字符。",
    },
    "Tên đăng nhập không được bỏ trống.": {
        vi: "Tên đăng nhập không được bỏ trống.",
        zh_CN: "用户名不能留空。",
    },
    "Tên đăng nhập từ 3 tới 30 ký tự.": {
        vi: "Tên đăng nhập từ 3 tới 30 ký tự.",
        zh_CN: "用户名 3 到 30 个字符。",
    },
    "Mật khẩu không được bỏ trống.": {
        vi: "Mật khẩu không được bỏ trống.",
        zh_CN: "密码不能留空。",
    },
    "Mật khẩu từ 6 tới 30 ký tự.": {
        vi: "Mật khẩu từ 6 tới 30 ký tự.",
        zh_CN: "6 到 30 个字符的密码。",
    },
    "Nhắc lại mật khẩu không được bỏ trống.": {
        vi: "Nhắc lại mật khẩu không được bỏ trống.",
        zh_CN: "重复密码不能留空。",
    },
    "Nhắc lại mật khẩu từ 6 tới 30 ký tự.": {
        vi: "Nhắc lại mật khẩu từ 6 tới 30 ký tự.",
        zh_CN: "重复 6 到 30 个字符的密码。",
    },
    "Họ tên từ tối đa 32 ký tự.": {
        vi: "Họ tên từ tối đa 32 ký tự.",
        zh_CN: "全名最多 32 个字符。",
    },
    "Mã giới thiệu tối đa 100 ký tự.": {
        vi: "Mã giới thiệu tối đa 100 ký tự.",
        zh_CN: "推荐代码最多 100 个字符。",
    },
    "Mật khẩu rút tiền tối đa 100 ký tự.": {
        vi: "Mật khẩu rút tiền tối đa 100 ký tự.",
        zh_CN: "取款密码最长为100个字符。",
    },
    "Loại game": {
        vi: "Loại game",
        zh_CN: "游戏类型",
    },
    "Loại cược": {
        vi: "Loại cược",
        zh_CN: "投注类型",
    },
    "Đặt tỉ lệ": {
        vi: "Đặt tỉ lệ",
        zh_CN: "设置比例",
    },
    "Khóa tìm kiếm tối đa 32 ký tự.": {
        vi: "Khóa tìm kiếm tối đa 32 ký tự.",
        zh_CN: "搜索键最多 32 个字符。",
    },
    "Khóa tìm kiếm không được bỏ trống.": {
        vi: "Khóa tìm kiếm không được bỏ trống.",
        zh_CN: "搜索关键字不能留空。",
    },
    "Kết quả xổ số": {
        vi: "Kết quả xổ số",
        zh_CN: "开奖结果",
    },
    "Ngân hàng": {
        vi: "Ngân hàng",
        zh_CN: "银行",
    },
    "Chủ tài khoản": {
        vi: "Chủ tài khoản",
        zh_CN: "帐户持有人",
    },
    "Số tài khoản": {
        vi: "Số tài khoản",
        zh_CN: "帐号",
    },
    "Tổng nhận": {
        vi: "Tổng nhận",
        zh_CN: "总收款",
    },
    "Ngân hàng không được bỏ trống.": {
        vi: "Ngân hàng không được bỏ trống.",
        zh_CN: "银行不能留空。",
    },
    "Chủ tài khoản không được bỏ trống.": {
        vi: "Chủ tài khoản không được bỏ trống.",
        zh_CN: "帐户持有人不能留空。",
    },
    "Chủ tài khoản từ 3 - 100 ký tự.": {
        vi: "Chủ tài khoản từ 3 - 100 ký tự.",
        zh_CN: "帐户持有人 3 - 100 个字符。",
    },
    "Số tài khoản không được bỏ trống.": {
        vi: "Số tài khoản không được bỏ trống.",
        zh_CN: "帐号不能留空。",
    },
    "Số tài khoản từ 3 - 100 ký tự.": {
        vi: "Số tài khoản từ 3 - 100 ký tự.",
        zh_CN: "帐号 3 - 100 个字符。",
    },
    "Trạng thái không được bỏ trống.": {
        vi: "Trạng thái không được bỏ trống.",
        zh_CN: "状态不能为空。",
    },
    "Cấp độ": {
        vi: "Cấp độ",
        zh_CN: "等级",
    },
    "Số dư hiện tại": {
        vi: "Số dư hiện tại",
        zh_CN: "当前余额",
    },
    "Thuộc đại lý": {
        vi: "Thuộc đại lý",
        zh_CN: "属于代理",
    },
    "Số điện thoại": {
        vi: "Số điện thoại",
        zh_CN: "电话号码",
    },
    "Chủ tài khoản tối đa 100 ký tự.": {
        vi: "Chủ tài khoản tối đa 100 ký tự.",
        zh_CN: "帐户持有人最多 100 个字符。",
    },
    "Số tài khoản tối đa 100 ký tự.": {
        vi: "Số tài khoản tối đa 100 ký tự.",
        zh_CN: "最大帐号为 100 个字符。",
    },
    "Số tiền nạp": {
        vi: "Số tiền nạp",
        zh_CN: "存款金额",
    },
    "Số tiền KM": {
        vi: "Số tiền KM",
        zh_CN: "公里数",
    },
    "KH đã nhận": {
        vi: "KH đã nhận",
        zh_CN: "客户收到",
    },
    "Số tiền rút": {
        vi: "Số tiền rút",
        zh_CN: "提款金额",
    },
    "Admin xử lý": {
        vi: "Admin xử lý",
        zh_CN: "管理员句柄",
    },
    "T/gian xử lý": {
        vi: "T/gian xử lý",
        zh_CN: "处理时间",
    },
    "Loại giao dịch": {
        vi: "Loại giao dịch",
        zh_CN: "交易类型",
    },
    "Số tiền GD": {
        vi: "Số tiền GD",
        zh_CN: "交易金额",
    },
    "Nội dung": {
        vi: "Nội dung",
        zh_CN: "内容",
    },
    "Thời gian": {
        vi: "Thời gian",
        zh_CN: "时间",
    },
    "Thông báo": {
        vi: "Thông báo",
        zh_CN: "通知",
    },
    Email: {
        vi: "Email",
        zh_CN: "电子邮件",
    },
    "Nhóm quản trị": {
        vi: "Nhóm quản trị",
        zh_CN: "管理组",
    },
    "Họ tên không được bỏ trống.": {
        vi: "Họ tên không được bỏ trống.",
        zh_CN: "名称不能留空。",
    },
    "Họ tên từ 3 tới 32 ký tự.": {
        vi: "Họ tên từ 3 tới 32 ký tự.",
        zh_CN: "名称从 3 到 32 个字符。",
    },
    "Tên vai trò": {
        vi: "Tên vai trò",
        zh_CN: "角色名称",
    },
    "Thành viên": {
        vi: "Thành viên",
        zh_CN: "成员",
    },
    "Tên vai trò không được bỏ trống.": {
        vi: "Tên vai trò không được bỏ trống.",
        zh_CN: "角色名称不能留空。",
    },
    "Tên vai trò tối đa 191 ký tự.": {
        vi: "Tên vai trò tối đa 191 ký tự.",
        zh_CN: "角色名称最多可包含 191 个字符。",
    },
    "Tên quyền": {
        vi: "Tên quyền",
        zh_CN: "权限名称",
    },
    "Nhóm có quyền": {
        vi: "Nhóm có quyền",
        zh_CN: "有权限的组",
    },
    "403 Forbidden": {
        vi: "403 Forbidden",
        zh_CN: "403禁止访问",
    },
    Dashboard: {
        vi: "Dashboard",
        zh_CN: "仪表板",
    },
    "Thống kê giao dịch": {
        vi: "Thống kê giao dịch",
        zh_CN: "交易统计",
    },
    "Thống kê cược": {
        vi: "Thống kê cược",
        zh_CN: "投注统计",
    },
    "Đặt tỉ lệ cược KH": {
        vi: "Đặt tỉ lệ cược KH",
        zh_CN: "设置赔率 完成",
    },
    "Tỉ lệ cược chung": {
        vi: "Tỉ lệ cược chung",
        zh_CN: "总赔率",
    },
    "Đại lý": {
        vi: "Đại lý",
        zh_CN: "机构",
    },
    "Danh sách": {
        vi: "Danh sách",
        zh_CN: "列表",
    },
    "Thêm tài khoản mới +": {
        vi: "Thêm tài khoản mới +",
        zh_CN: "添加新帐户 +",
    },
    "Cập nhật tài khoản": {
        vi: "Cập nhật tài khoản",
        zh_CN: "更新账户",
    },
    "Đổi mật khẩu": {
        vi: "Đổi mật khẩu",
        zh_CN: "更改密码",
    },
    "Thông tin tài khoản": {
        vi: "Thông tin tài khoản",
        zh_CN: "帐户信息",
    },
    "Khách hàng": {
        vi: "Khách hàng",
        zh_CN: "客户",
    },
    "Yêu cầu nạp tiền": {
        vi: "Yêu cầu nạp tiền",
        zh_CN: "要求存款",
    },
    "Yêu cầu rút tiền": {
        vi: "Yêu cầu rút tiền",
        zh_CN: "要求提款",
    },
    "Game Nhanh3/10phút": {
        vi: "Game Nhanh3/10phút",
        zh_CN: "快速游戏3/10min",
    },
    "Game Nhanh3/5phút": {
        vi: "Game Nhanh3/5phút",
        zh_CN: "快速游戏3/5min",
    },
    "Game Nhanh3/3phút": {
        vi: "Game Nhanh3/3phút",
        zh_CN: "快速游戏3/3min",
    },
    "Game Nhanh3/1phút": {
        vi: "Game Nhanh3/1phút",
        zh_CN: "快速游戏3/1min",
    },
    "Game 5D / 3 phút": {
        vi: "Game 5D / 3 phút",
        zh_CN: "5D游戏/3分钟",
    },
    "Cài đặt ngân hàng": {
        vi: "Cài đặt ngân hàng",
        zh_CN: "银行设置",
    },
    "Thêm ngân hàng mới +": {
        vi: "Thêm ngân hàng mới +",
        zh_CN: "添加新银行 +",
    },
    "Cập nhật ngân hàng": {
        vi: "Cập nhật ngân hàng",
        zh_CN: "银行更新",
    },
    "Quản trị viên": {
        vi: "Quản trị viên",
        zh_CN: "管理员",
    },
    "Vai trò": {
        vi: "Vai trò",
        zh_CN: "角色",
    },
    "Thêm vai trò mới +": {
        vi: "Thêm vai trò mới +",
        zh_CN: "添加新角色 +",
    },
    "Cập nhật vai trò": {
        vi: "Cập nhật vai trò",
        zh_CN: "更新角色",
    },
    "Quyền quản trị": {
        vi: "Quyền quản trị",
        zh_CN: "管理员权限",
    },
    "30 bản ghi": {
        vi: "30 bản ghi",
        zh_CN: "30条记录",
    },
    "50 bản ghi": {
        vi: "50 bản ghi",
        zh_CN: "50条记录",
    },
    "100 bản ghi": {
        vi: "100 bản ghi",
        zh_CN: "100条记录",
    },
    "300 bản ghi": {
        vi: "300 bản ghi",
        zh_CN: "300条记录",
    },
    "500 bản ghi": {
        vi: "500 bản ghi",
        zh_CN: "500条记录",
    },
    "800 bản ghi": {
        vi: "800 bản ghi",
        zh_CN: "800条记录",
    },
    "1000 bản ghi": {
        vi: "1000 bản ghi",
        zh_CN: "1000条记录",
    },
    "2000 bản ghi": {
        vi: "2000 bản ghi",
        zh_CN: "2000条记录",
    },
    "Giảm dần thời gian tạo": {
        vi: "Giảm dần thời gian tạo",
        zh_CN: "逐渐减少创建时间",
    },
    "Tăng dần thời gian tạo": {
        vi: "Tăng dần thời gian tạo",
        zh_CN: "逐渐增加创建时间",
    },
    "Giảm dần thời cập nhật": {
        vi: "Giảm dần thời cập nhật",
        zh_CN: "逐渐减少更新时间",
    },
    "Tăng dần thời cập nhật": {
        vi: "Tăng dần thời cập nhật",
        zh_CN: "逐渐增加更新时间",
    },
    "(A-Z) theo tên": {
        vi: "(A-Z) theo tên",
        zh_CN: "(A-Z) 按名称",
    },
    "(Z-A) theo tên": {
        vi: "(Z-A) theo tên",
        zh_CN: "(Z-A) 的名字",
    },
    "Mở ": {
        vi: "Mở ",
        zh_CN: "打开",
    },
    Đóng: {
        vi: "Đóng",
        zh_CN: "关闭",
    },
    "Hoạt động": {
        vi: "Hoạt động",
        zh_CN: "工作",
    },
    "Đang khóa": {
        vi: "Đang khóa",
        zh_CN: "锁",
    },
    "Mở cược": {
        vi: "Mở cược",
        zh_CN: "公开投注",
    },
    "Đóng cược": {
        vi: "Đóng cược",
        zh_CN: "关闭投注",
    },
    "VIP 0": {
        vi: "VIP 0",
        zh_CN: "贵宾 0",
    },
    "VIP 1": {
        vi: "VIP 1",
        zh_CN: "贵宾1",
    },
    "VIP 2": {
        vi: "VIP 2",
        zh_CN: "贵宾2",
    },
    "VIP 3": {
        vi: "VIP 3",
        zh_CN: "贵宾 3",
    },
    "VIP 4": {
        vi: "VIP 4",
        zh_CN: "贵宾4",
    },
    "VIP 5": {
        vi: "VIP 5",
        zh_CN: "贵宾5",
    },
    "VIP 6": {
        vi: "VIP 6",
        zh_CN: "贵宾6",
    },
    "VIP 7": {
        vi: "VIP 7",
        zh_CN: "贵宾7",
    },
    "VIP 8": {
        vi: "VIP 8",
        zh_CN: "贵宾8",
    },
    "VIP 9": {
        vi: "VIP 9",
        zh_CN: "贵宾9",
    },
    "VIP 10": {
        vi: "VIP 10",
        zh_CN: "贵宾10",
    },
    "VIP 11": {
        vi: "VIP 11",
        zh_CN: "贵宾11",
    },
    Nam: {
        vi: "Nam",
        zh_CN: "男性",
    },
    Nữ: {
        vi: "Nữ",
        zh_CN: "女性",
    },
    Khác: {
        vi: "Khác",
        zh_CN: "其他",
    },
    "Đã duyệt": {
        vi: "Đã duyệt",
        zh_CN: "转入",
    },
    "Không duyệt": {
        vi: "Không duyệt",
        zh_CN: "没有转移",
    },
    "Đang chờ": {
        vi: "Đang chờ",
        zh_CN: "等待",
    },
    "Đã xử lý": {
        vi: "Đã xử lý",
        zh_CN: "处理",
    },
    "Chưa xử lý": {
        vi: "Chưa xử lý",
        zh_CN: "无流程",
    },
    "Nạp tiền": {
        vi: "Nạp tiền",
        zh_CN: "充值",
    },
    "Rút tiền": {
        vi: "Rút tiền",
        zh_CN: "取钱",
    },
    "Đặt cược": {
        vi: "Đặt cược",
        zh_CN: "投注",
    },
    "Admin Thu hồi": {
        vi: "Admin Thu hồi",
        zh_CN: "管理员退出",
    },
    "Admin Cộng tiền": {
        vi: "Admin Cộng tiền",
        zh_CN: "管理员加钱",
    },
    "Thắng cược": {
        vi: "Thắng cược",
        zh_CN: "赢得赌注",
    },
    "Khuyến mại": {
        vi: "Khuyến mại",
        zh_CN: "促销活动",
    },
    "Hoàn tiền rút": {
        vi: "Hoàn tiền rút",
        zh_CN: "返现提现",
    },
    "Nhanh3/10phút": {
        vi: "Nhanh3/10phút",
        zh_CN: "快速3/10min",
    },
    "Nhanh3/5phút": {
        vi: "Nhanh3/5phút",
        zh_CN: "快速3/5分钟",
    },
    "Nhanh3/3phút": {
        vi: "Nhanh3/3phút",
        zh_CN: "Quick3/3分钟",
    },
    "Nhanh3/1phút": {
        vi: "Nhanh3/1phút",
        zh_CN: "快速3/1min",
    },
    "Hàng vạn": {
        vi: "Hàng vạn",
        zh_CN: "千",
    },
    "Hàng ngàn": {
        vi: "Hàng ngàn",
        zh_CN: "千",
    },
    "Hàng trăm": {
        vi: "Hàng trăm",
        zh_CN: "数百个",
    },
    "Hàng chục": {
        vi: "Hàng chục",
        zh_CN: "许多",
    },
    "Hàng đơn vị": {
        vi: "Hàng đơn vị",
        zh_CN: "单位",
    },
    "Xúc xắc thứ 1": {
        vi: "Xúc xắc thứ 1",
        zh_CN: "第一个骰子",
    },
    "Xúc xắc thứ 2": {
        vi: "Xúc xắc thứ 2",
        zh_CN: "第二个骰子",
    },
    "Xúc xắc thứ 3": {
        vi: "Xúc xắc thứ 3",
        zh_CN: "第三个骰子",
    },
    "Hệ thống đang Mở cược": {
        vi: "Hệ thống đang Mở cược",
        zh_CN: "系统开始投注",
    },
    "Đi tới trang chủ": {
        vi: "Đi tới trang chủ",
        zh_CN: "去首页",
    },
    "Trang chủ": {
        vi: "Trang chủ",
        zh_CN: "主页",
    },
    "Hi !": {
        vi: "Hi !",
        zh_CN: "你好！",
    },
    "Quay lại": {
        vi: "Quay lại",
        zh_CN: "回来",
    },
    "Lọc bản ghi :": {
        vi: "Lọc bản ghi :",
        zh_CN: "筛选记录：",
    },
    "Số bản ghi (Mặc định)": {
        vi: "Số bản ghi (Mặc định)",
        zh_CN: "记录数（默认）",
    },
    "Sắp xếp (Mặc định)": {
        vi: "Sắp xếp (Mặc định)",
        zh_CN: "排序（默认）",
    },
    "Tất cả thời gian tạo": {
        vi: "Tất cả thời gian tạo",
        zh_CN: "全部创作时间",
    },
    "Tẩt cả loại giao dịch": {
        vi: "Tẩt cả loại giao dịch",
        zh_CN: "所有类型的交易",
    },
    "Tìm ID khách hàng...": {
        vi: "Tìm ID khách hàng...",
        zh_CN: "查找客户 ID...",
    },
    "Tìm tên đăng nhập...": {
        vi: "Tìm tên đăng nhập...",
        zh_CN: "查找您的用户名...",
    },
    "Tìm mã giới thiệu...": {
        vi: "Tìm mã giới thiệu...",
        zh_CN: "查找推荐代码...",
    },
    "Lọc / Cập nhật": {
        vi: "Lọc / Cập nhật",
        zh_CN: "过滤/更新",
    },
    Reset: {
        vi: "Reset",
        zh_CN: "重置",
    },
    Xóa: {
        vi: "Xóa",
        zh_CN: "擦除",
    },
    "Copyright © 2022. All Rights Reserved.": {
        vi: "Copyright © 2022. All Rights Reserved.",
        zh_CN: "版权所有 © 2022。保留所有权利。",
    },
    "Welcome to the administration system !": {
        vi: "Welcome to the administration system !",
        zh_CN: "欢迎使用管理系统！",
    },
    "Tẩt cả trò chơi": {
        vi: "Tẩt cả trò chơi",
        zh_CN: "所有游戏",
    },
    "Tỉ lệ cược này sẽ ghi đè tỉ lệ cược chung. Giá trị mới sẽ chỉ áp dụng cho đơn cược mới.":
        {
            vi: "Tỉ lệ cược này sẽ ghi đè tỉ lệ cược chung. Giá trị mới sẽ chỉ áp dụng cho đơn cược mới.",
            zh_CN: "该赔率将高于一般赔率。 新值将仅适用于新投注。",
        },
    "Tỉ lệ cược này sẽ được áp dụng ngay và luôn cho tài khoản khách hàng": {
        vi: "Tỉ lệ cược này sẽ được áp dụng ngay và luôn cho tài khoản khách hàng",
        zh_CN: "此赔率将立即应用于客户账户",
    },
    "Thêm tỉ lệ cược": {
        vi: "Thêm tỉ lệ cược",
        zh_CN: "更多赔率",
    },
    "Tìm khách hàng:": {
        vi: "Tìm khách hàng:",
        zh_CN: "寻找客户：",
    },
    "Tìm kiếm khách hàng cần chỉnh tỉ lệ cược...": {
        vi: "Tìm kiếm khách hàng cần chỉnh tỉ lệ cược...",
        zh_CN: "寻找需要调整赔率的客户...",
    },
    "Tìm kiếm": {
        vi: "Tìm kiếm",
        zh_CN: "搜索",
    },
    "Nhập [ ID ] hoặc [ Tên đăng nhập ] hoặc [ Mã giới thiệu ] rồi bấm Tìm kiếm":
        {
            vi: "Nhập [ ID ] hoặc [ Tên đăng nhập ] hoặc [ Mã giới thiệu ] rồi bấm Tìm kiếm",
            zh_CN: "输入 [ID] 或 [Username] 或 [Referral code] 然后点击 Search",
        },
    "Tên đăng nhập:": {
        vi: "Tên đăng nhập:",
        zh_CN: "用户名：",
    },
    "Đồng ý": {
        vi: "Đồng ý",
        zh_CN: "同意",
    },
    "Tìm ID...": {
        vi: "Tìm ID...",
        zh_CN: "找身份证...",
    },
    "Nếu để trống, giá trị sẽ đưa về mặc định. Giá trị mới sẽ chỉ áp dụng cho đơn cược mới.":
        {
            vi: "Nếu để trống, giá trị sẽ đưa về mặc định. Giá trị mới sẽ chỉ áp dụng cho đơn cược mới.",
            zh_CN: "如果留空，该值将恢复为默认值。 新值将仅适用于新投注。",
        },
    "Tỉ lệ cược chung sẽ được áp dụng sau khi kỳ game hiện tại kết thúc": {
        vi: "Tỉ lệ cược chung sẽ được áp dụng sau khi kỳ game hiện tại kết thúc",
        zh_CN: "一般赔率将在当前游戏期结束后应用",
    },
    "Xác nhận": {
        vi: "Xác nhận",
        zh_CN: "确认",
    },
    "[Tổng]{Tổng}_Tài": {
        vi: "[Tổng]{Tổng}_Tài",
        zh_CN: "[总计]{总计}_太",
    },
    "[Tổng]{Tổng}_Xỉu": {
        vi: "[Tổng]{Tổng}_Xỉu",
        zh_CN: "[总计]{总计}_不足",
    },
    "[Tổng]{Tổng}_Lẻ": {
        vi: "[Tổng]{Tổng}_Lẻ",
        zh_CN: "[总计]{总计}_Odd",
    },
    "[Tổng]{Tổng}_Chẵn": {
        vi: "[Tổng]{Tổng}_Chẵn",
        zh_CN: "[总计]{总计}_偶数",
    },
    "[Tổng]{Tổng}_TàiLẻ": {
        vi: "[Tổng]{Tổng}_TàiLẻ",
        zh_CN: "[总计]{总计}_收入",
    },
    "[Tổng]{Tổng}_TàiChẵn": {
        vi: "[Tổng]{Tổng}_TàiChẵn",
        zh_CN: "[总计]{总计}_事件",
    },
    "[Tổng]{Tổng}_XỉuLẻ": {
        vi: "[Tổng]{Tổng}_XỉuLẻ",
        zh_CN: "[总计]{总计}_下单",
    },
    "[Tổng]{Tổng}_XỉuChẵn": {
        vi: "[Tổng]{Tổng}_XỉuChẵn",
        zh_CN: "[Total]{Total}_Under Even",
    },
    "[Tổng]{Tổng}_3": {
        vi: "[Tổng]{Tổng}_3",
        zh_CN: "[总计]{总计}_3",
    },
    "[Tổng]{Tổng}_4": {
        vi: "[Tổng]{Tổng}_4",
        zh_CN: "[总计]{总计}_4",
    },
    "[Tổng]{Tổng}_5": {
        vi: "[Tổng]{Tổng}_5",
        zh_CN: "[总计]{总计}_5",
    },
    "[Tổng]{Tổng}_6": {
        vi: "[Tổng]{Tổng}_6",
        zh_CN: "[总计]{总计}_6",
    },
    "[Tổng]{Tổng}_7": {
        vi: "[Tổng]{Tổng}_7",
        zh_CN: "[总计]{总计}_7",
    },
    "[Tổng]{Tổng}_8": {
        vi: "[Tổng]{Tổng}_8",
        zh_CN: "[总计]{总计}_8",
    },
    "[Tổng]{Tổng}_9": {
        vi: "[Tổng]{Tổng}_9",
        zh_CN: "[总计]{总计}_9",
    },
    "[Tổng]{Tổng}_10": {
        vi: "[Tổng]{Tổng}_10",
        zh_CN: "[总计]{总计}_10",
    },
    "[Tổng]{Tổng}_11": {
        vi: "[Tổng]{Tổng}_11",
        zh_CN: "[总计]{总计}_11",
    },
    "[Tổng]{Tổng}_12": {
        vi: "[Tổng]{Tổng}_12",
        zh_CN: "[总计]{总计}_12",
    },
    "[Tổng]{Tổng}_13": {
        vi: "[Tổng]{Tổng}_13",
        zh_CN: "[总计]{总计}_13",
    },
    "[Tổng]{Tổng}_14": {
        vi: "[Tổng]{Tổng}_14",
        zh_CN: "[总计]{总计}_14",
    },
    "[Tổng]{Tổng}_15": {
        vi: "[Tổng]{Tổng}_15",
        zh_CN: "[总计]{总计}_15",
    },
    "[Tổng]{Tổng}_16": {
        vi: "[Tổng]{Tổng}_16",
        zh_CN: "[总计]{总计}_16",
    },
    "[Tổng]{Tổng}_17": {
        vi: "[Tổng]{Tổng}_17",
        zh_CN: "[总计]{总计}_17",
    },
    "[Tổng]{Tổng}_18": {
        vi: "[Tổng]{Tổng}_18",
        zh_CN: "[总计]{总计}_18",
    },
    "[TamCôChung]{TamCôChung}_TamCôChung": {
        vi: "[TamCôChung]{TamCôChung}_TamCôChung",
        zh_CN: "[TamCoChung]{TamCoChung}_TamCoChung",
    },
    "[TamCô]{TamCô}_1-1-1": {
        vi: "[TamCô]{TamCô}_1-1-1",
        zh_CN: "[TamCo]{TamCo}_1-1-1",
    },
    "[TamCô]{TamCô}_2-2-2": {
        vi: "[TamCô]{TamCô}_2-2-2",
        zh_CN: "[TamCo]{TamCo}_2-2-2",
    },
    "[TamCô]{TamCô}_3-3-3": {
        vi: "[TamCô]{TamCô}_3-3-3",
        zh_CN: "[TamCo]{TamCo}_3-3-3",
    },
    "[TamCô]{TamCô}_4-4-4": {
        vi: "[TamCô]{TamCô}_4-4-4",
        zh_CN: "[TamCo]{TamCo}_4-4-4",
    },
    "[TamCô]{TamCô}_5-5-5": {
        vi: "[TamCô]{TamCô}_5-5-5",
        zh_CN: "[TamCo]{TamCo}_5-5-5",
    },
    "[TamCô]{TamCô}_6-6-6": {
        vi: "[TamCô]{TamCô}_6-6-6",
        zh_CN: "[TamCo]{TamCo}_6-6-6",
    },
    "[3SốKhácNhau]{3SốKhácNhau}_1": {
        vi: "[3SốKhácNhau]{3SốKhácNhau}_1",
        zh_CN: "[3个不同的数字]{3个不同的数字}_1",
    },
    "[3SốKhácNhau]{3SốKhácNhau}_2": {
        vi: "[3SốKhácNhau]{3SốKhácNhau}_2",
        zh_CN: "[3个不同的数字]{3个不同的数字}_2",
    },
    "[3SốKhácNhau]{3SốKhácNhau}_3": {
        vi: "[3SốKhácNhau]{3SốKhácNhau}_3",
        zh_CN: "[3个不同的数字]{3个不同的数字}_3",
    },
    "[3SốKhácNhau]{3SốKhácNhau}_4": {
        vi: "[3SốKhácNhau]{3SốKhácNhau}_4",
        zh_CN: "[3个不同的数字]{3个不同的数字}_4",
    },
    "[3SốKhácNhau]{3SốKhácNhau}_5": {
        vi: "[3SốKhácNhau]{3SốKhácNhau}_5",
        zh_CN: "[3个不同的数字]{3个不同的数字}_5",
    },
    "[3SốKhácNhau]{3SốKhácNhau}_6": {
        vi: "[3SốKhácNhau]{3SốKhácNhau}_6",
        zh_CN: "[3个不同的数字]{3个不同的数字}_6",
    },
    "[TổngSảnh]{TổngSảnh}_TổngSảnh": {
        vi: "[TổngSảnh]{TổngSảnh}_TổngSảnh",
        zh_CN: "[大厅总数]{大厅总数}_大厅总数",
    },
    "[Đôi]{Đôi}_1-1": {
        vi: "[Đôi]{Đôi}_1-1",
        zh_CN: "[双]{双}_1-1",
    },
    "[Đôi]{Đôi}_2-2": {
        vi: "[Đôi]{Đôi}_2-2",
        zh_CN: "[双]{双}_2-2",
    },
    "[Đôi]{Đôi}_3-3": {
        vi: "[Đôi]{Đôi}_3-3",
        zh_CN: "[双]{双}_3-3",
    },
    "[Đôi]{Đôi}_4-4": {
        vi: "[Đôi]{Đôi}_4-4",
        zh_CN: "[双]{双}_4-4",
    },
    "[Đôi]{Đôi}_5-5": {
        vi: "[Đôi]{Đôi}_5-5",
        zh_CN: "[双]{双}_5-5",
    },
    "[Đôi]{Đôi}_6-6": {
        vi: "[Đôi]{Đôi}_6-6",
        zh_CN: "[双]{双}_6-6",
    },
    "[Đôi+1]{Đôi+1}_1-1": {
        vi: "[Đôi+1]{Đôi+1}_1-1",
        zh_CN: "[双+1]{双+1}_1-1",
    },
    "[Đôi+1]{Đôi+1}_2-2": {
        vi: "[Đôi+1]{Đôi+1}_2-2",
        zh_CN: "[双+1]{双+1}_2-2",
    },
    "[Đôi+1]{Đôi+1}_3-3": {
        vi: "[Đôi+1]{Đôi+1}_3-3",
        zh_CN: "[双+1]{双+1}_3-3",
    },
    "[Đôi+1]{Đôi+1}_4-4": {
        vi: "[Đôi+1]{Đôi+1}_4-4",
        zh_CN: "[双+1]{双+1}_4-4",
    },
    "[Đôi+1]{Đôi+1}_5-5": {
        vi: "[Đôi+1]{Đôi+1}_5-5",
        zh_CN: "[双+1]{双+1}_5-5",
    },
    "[Đôi+1]{Đôi+1}_6-6": {
        vi: "[Đôi+1]{Đôi+1}_6-6",
        zh_CN: "[双+1]{双+1}_6-6",
    },
    "[Đôi+1]{Đôi+1}_1": {
        vi: "[Đôi+1]{Đôi+1}_1",
        zh_CN: "[双+1]{双+1}_1",
    },
    "[Đôi+1]{Đôi+1}_2": {
        vi: "[Đôi+1]{Đôi+1}_2",
        zh_CN: "[双+1]{双+1}_2",
    },
    "[Đôi+1]{Đôi+1}_3": {
        vi: "[Đôi+1]{Đôi+1}_3",
        zh_CN: "[双+1]{双+1}_3",
    },
    "[Đôi+1]{Đôi+1}_4": {
        vi: "[Đôi+1]{Đôi+1}_4",
        zh_CN: "[双+1]{双+1}_4",
    },
    "[Đôi+1]{Đôi+1}_5": {
        vi: "[Đôi+1]{Đôi+1}_5",
        zh_CN: "[双+1]{双+1}_5",
    },
    "[Đôi+1]{Đôi+1}_6": {
        vi: "[Đôi+1]{Đôi+1}_6",
        zh_CN: "[双+1]{双+1}_6",
    },
    "[2KhácNhau]{2KhácNhau}_1": {
        vi: "[2KhácNhau]{2KhácNhau}_1",
        zh_CN: "[2不同]{2不同}_1",
    },
    "[2KhácNhau]{2KhácNhau}_2": {
        vi: "[2KhácNhau]{2KhácNhau}_2",
        zh_CN: "[2不同]{2不同}_2",
    },
    "[2KhácNhau]{2KhácNhau}_3": {
        vi: "[2KhácNhau]{2KhácNhau}_3",
        zh_CN: "[2不同]{2不同}_3",
    },
    "[2KhácNhau]{2KhácNhau}_4": {
        vi: "[2KhácNhau]{2KhácNhau}_4",
        zh_CN: "[2不同]{2不同}_4",
    },
    "[2KhácNhau]{2KhácNhau}_5": {
        vi: "[2KhácNhau]{2KhácNhau}_5",
        zh_CN: "[2不同]{2不同}_5",
    },
    "[2KhácNhau]{2KhácNhau}_6": {
        vi: "[2KhácNhau]{2KhácNhau}_6",
        zh_CN: "[2不同]{2不同}_6",
    },
    "[Đôibên]{Khác}_Tài": {
        vi: "[Đôibên]{Khác}_Tài",
        zh_CN: "【双人派对】{Other}_Tai",
    },
    "[Đôibên]{Khác}_Xỉu": {
        vi: "[Đôibên]{Khác}_Xỉu",
        zh_CN: "【双面】{其他}_下",
    },
    "[Đôibên]{Khác}_Lẻ": {
        vi: "[Đôibên]{Khác}_Lẻ",
        zh_CN: "【双面】{Other}_Odd",
    },
    "[Đôibên]{Khác}_Chẵn": {
        vi: "[Đôibên]{Khác}_Chẵn",
        zh_CN: "【双面】{Other}_Even",
    },
    "[Đôibên]{Khác}_TàiLẻ": {
        vi: "[Đôibên]{Khác}_TàiLẻ",
        zh_CN: "[双人派对]{Other}_Exodus",
    },
    "[Đôibên]{Khác}_TàiChẵn": {
        vi: "[Đôibên]{Khác}_TàiChẵn",
        zh_CN: "【双面】{Other}_Even",
    },
    "[Đôibên]{Khác}_XỉuLẻ": {
        vi: "[Đôibên]{Khác}_XỉuLẻ",
        zh_CN: "【双面】{其他}_下单",
    },
    "[Đôibên]{Khác}_XỉuChẵn": {
        vi: "[Đôibên]{Khác}_XỉuChẵn",
        zh_CN: "[男生]{其他}_Under Even",
    },
    "[Tổng]{Khác}_3": {
        vi: "[Tổng]{Khác}_3",
        zh_CN: "[总计]{其他}_3",
    },
    "[Tổng]{Khác}_4": {
        vi: "[Tổng]{Khác}_4",
        zh_CN: "[总计]{其他}_4",
    },
    "[Tổng]{Khác}_5": {
        vi: "[Tổng]{Khác}_5",
        zh_CN: "[总计]{其他}_5",
    },
    "[Tổng]{Khác}_6": {
        vi: "[Tổng]{Khác}_6",
        zh_CN: "[总计]{其他}_6",
    },
    "[Tổng]{Khác}_7": {
        vi: "[Tổng]{Khác}_7",
        zh_CN: "[总计]{其他}_7",
    },
    "[Tổng]{Khác}_8": {
        vi: "[Tổng]{Khác}_8",
        zh_CN: "[总计]{其他}_8",
    },
    "[Tổng]{Khác}_9": {
        vi: "[Tổng]{Khác}_9",
        zh_CN: "[总计]{其他}_9",
    },
    "[Tổng]{Khác}_10": {
        vi: "[Tổng]{Khác}_10",
        zh_CN: "[总计]{其他}_10",
    },
    "[Tổng]{Khác}_11": {
        vi: "[Tổng]{Khác}_11",
        zh_CN: "[总计]{其他}_11",
    },
    "[Tổng]{Khác}_12": {
        vi: "[Tổng]{Khác}_12",
        zh_CN: "[总计]{其他}_12",
    },
    "[Tổng]{Khác}_13": {
        vi: "[Tổng]{Khác}_13",
        zh_CN: "[总计]{其他}_13",
    },
    "[Tổng]{Khác}_14": {
        vi: "[Tổng]{Khác}_14",
        zh_CN: "[总计]{其他}_14",
    },
    "[Tổng]{Khác}_15": {
        vi: "[Tổng]{Khác}_15",
        zh_CN: "[总计]{其他}_15",
    },
    "[Tổng]{Khác}_16": {
        vi: "[Tổng]{Khác}_16",
        zh_CN: "[总计]{其他}_16",
    },
    "[Tổng]{Khác}_17": {
        vi: "[Tổng]{Khác}_17",
        zh_CN: "[总计]{其他}_17",
    },
    "[Tổng]{Khác}_18": {
        vi: "[Tổng]{Khác}_18",
        zh_CN: "[总计]{其他}_18",
    },
    "[Khớp2Số]{Khác}_1-2": {
        vi: "[Khớp2Số]{Khác}_1-2",
        zh_CN: "[Match2Number]{其他}_1-2",
    },
    "[Khớp2Số]{Khác}_1-3": {
        vi: "[Khớp2Số]{Khác}_1-3",
        zh_CN: "[Match2Number]{其他}_1-3",
    },
    "[Khớp2Số]{Khác}_1-4": {
        vi: "[Khớp2Số]{Khác}_1-4",
        zh_CN: "[Match2Number]{其他}_1-4",
    },
    "[Khớp2Số]{Khác}_1-5": {
        vi: "[Khớp2Số]{Khác}_1-5",
        zh_CN: "[Match2Number]{其他}_1-5",
    },
    "[Khớp2Số]{Khác}_1-6": {
        vi: "[Khớp2Số]{Khác}_1-6",
        zh_CN: "[Match2Number]{其他}_1-6",
    },
    "[Khớp2Số]{Khác}_2-3": {
        vi: "[Khớp2Số]{Khác}_2-3",
        zh_CN: "[Match2Number]{其他}_2-3",
    },
    "[Khớp2Số]{Khác}_2-4": {
        vi: "[Khớp2Số]{Khác}_2-4",
        zh_CN: "[Match2Number]{其他}_2-4",
    },
    "[Khớp2Số]{Khác}_2-5": {
        vi: "[Khớp2Số]{Khác}_2-5",
        zh_CN: "[Match2Number]{其他}_2-5",
    },
    "[Khớp2Số]{Khác}_2-6": {
        vi: "[Khớp2Số]{Khác}_2-6",
        zh_CN: "[Match2Number]{其他}_2-6",
    },
    "[Khớp2Số]{Khác}_3-4": {
        vi: "[Khớp2Số]{Khác}_3-4",
        zh_CN: "[Match2Number]{其他}_3-4",
    },
    "[Khớp2Số]{Khác}_3-5": {
        vi: "[Khớp2Số]{Khác}_3-5",
        zh_CN: "[Match2Number]{其他}_3-5",
    },
    "[Khớp2Số]{Khác}_3-6": {
        vi: "[Khớp2Số]{Khác}_3-6",
        zh_CN: "[Match2Number]{其他}_3-6",
    },
    "[Khớp2Số]{Khác}_4-5": {
        vi: "[Khớp2Số]{Khác}_4-5",
        zh_CN: "[Match2Number]{其他}_4-5",
    },
    "[Khớp2Số]{Khác}_4-6": {
        vi: "[Khớp2Số]{Khác}_4-6",
        zh_CN: "[Match2Number]{其他}_4-6",
    },
    "[Khớp2Số]{Khác}_5-6": {
        vi: "[Khớp2Số]{Khác}_5-6",
        zh_CN: "[Match2Number]{其他}_5-6",
    },
    "[Đôi]{Khác}_1-1": {
        vi: "[Đôi]{Khác}_1-1",
        zh_CN: "[双]{其他}_1-1",
    },
    "[Đôi]{Khác}_2-2": {
        vi: "[Đôi]{Khác}_2-2",
        zh_CN: "[双]{其他}_2-2",
    },
    "[Đôi]{Khác}_3-3": {
        vi: "[Đôi]{Khác}_3-3",
        zh_CN: "[双]{其他}_3-3",
    },
    "[Đôi]{Khác}_4-4": {
        vi: "[Đôi]{Khác}_4-4",
        zh_CN: "[双]{其他}_4-4",
    },
    "[Đôi]{Khác}_5-5": {
        vi: "[Đôi]{Khác}_5-5",
        zh_CN: "[双]{其他}_5-5",
    },
    "[Đôi]{Khác}_6-6": {
        vi: "[Đôi]{Khác}_6-6",
        zh_CN: "[双]{其他}_6-6",
    },
    "[TamCô]{Khác}_1-1-1": {
        vi: "[TamCô]{Khác}_1-1-1",
        zh_CN: "[TamCo]{其他}_1-1-1",
    },
    "[TamCô]{Khác}_2-2-2": {
        vi: "[TamCô]{Khác}_2-2-2",
        zh_CN: "[TamCo]{其他}_2-2-2",
    },
    "[TamCô]{Khác}_3-3-3": {
        vi: "[TamCô]{Khác}_3-3-3",
        zh_CN: "[TamCo]{其他}_3-3-3",
    },
    "[TamCô]{Khác}_4-4-4": {
        vi: "[TamCô]{Khác}_4-4-4",
        zh_CN: "[TamCo]{其他}_4-4-4",
    },
    "[TamCô]{Khác}_5-5-5": {
        vi: "[TamCô]{Khác}_5-5-5",
        zh_CN: "[TamCo]{其他}_5-5-5",
    },
    "[TamCô]{Khác}_6-6-6": {
        vi: "[TamCô]{Khác}_6-6-6",
        zh_CN: "[TamCo]{其他}_6-6-6",
    },
    "[BấtKỳ1]{Khác}_1": {
        vi: "[BấtKỳ1]{Khác}_1",
        zh_CN: "[Any1]{其他}_1",
    },
    "[BấtKỳ1]{Khác}_2": {
        vi: "[BấtKỳ1]{Khác}_2",
        zh_CN: "[Any1]{其他}_2",
    },
    "[BấtKỳ1]{Khác}_3": {
        vi: "[BấtKỳ1]{Khác}_3",
        zh_CN: "[Any1]{其他}_3",
    },
    "[BấtKỳ1]{Khác}_4": {
        vi: "[BấtKỳ1]{Khác}_4",
        zh_CN: "[Any1]{其他}_4",
    },
    "[BấtKỳ1]{Khác}_5": {
        vi: "[BấtKỳ1]{Khác}_5",
        zh_CN: "[Any1]{其他}_5",
    },
    "[BấtKỳ1]{Khác}_6": {
        vi: "[BấtKỳ1]{Khác}_6",
        zh_CN: "[Any1]{其他}_6",
    },
    "[3Trên4]{3Trên4}_1-2-3-4": {
        vi: "[3Trên4]{3Trên4}_1-2-3-4",
        zh_CN: "[3Over4]{3Over4}_1-2-3-4",
    },
    "[3Trên4]{3Trên4}_1-2-3-5": {
        vi: "[3Trên4]{3Trên4}_1-2-3-5",
        zh_CN: "[3Over4]{3Over4}_1-2-3-5",
    },
    "[3Trên4]{3Trên4}_1-2-3-6": {
        vi: "[3Trên4]{3Trên4}_1-2-3-6",
        zh_CN: "[3Over4]{3Over4}_1-2-3-6",
    },
    "[3Trên4]{3Trên4}_1-2-4-5": {
        vi: "[3Trên4]{3Trên4}_1-2-4-5",
        zh_CN: "[3Over4]{3Over4}_1-2-4-5",
    },
    "[3Trên4]{3Trên4}_1-2-4-6": {
        vi: "[3Trên4]{3Trên4}_1-2-4-6",
        zh_CN: "[3Over4]{3Over4}_1-2-4-6",
    },
    "[3Trên4]{3Trên4}_1-2-5-6": {
        vi: "[3Trên4]{3Trên4}_1-2-5-6",
        zh_CN: "[3Over4]{3Over4}_1-2-5-6",
    },
    "[3Trên4]{3Trên4}_1-3-4-5": {
        vi: "[3Trên4]{3Trên4}_1-3-4-5",
        zh_CN: "[3Over4]{3Over4}_1-3-4-5",
    },
    "[3Trên4]{3Trên4}_1-3-4-6": {
        vi: "[3Trên4]{3Trên4}_1-3-4-6",
        zh_CN: "[3Over4]{3Over4}_1-3-4-6",
    },
    "[3Trên4]{3Trên4}_1-3-5-6": {
        vi: "[3Trên4]{3Trên4}_1-3-5-6",
        zh_CN: "[3Over4]{3Over4}_1-3-5-6",
    },
    "[3Trên4]{3Trên4}_1-4-5-6": {
        vi: "[3Trên4]{3Trên4}_1-4-5-6",
        zh_CN: "[3Over4]{3Over4}_1-4-5-6",
    },
    "[3Trên4]{3Trên4}_2-3-4-5": {
        vi: "[3Trên4]{3Trên4}_2-3-4-5",
        zh_CN: "[3Over4]{3Over4}_2-3-4-5",
    },
    "[3Trên4]{3Trên4}_2-3-4-6": {
        vi: "[3Trên4]{3Trên4}_2-3-4-6",
        zh_CN: "[3Over4]{3Over4}_2-3-4-6",
    },
    "[3Trên4]{3Trên4}_2-3-5-6": {
        vi: "[3Trên4]{3Trên4}_2-3-5-6",
        zh_CN: "[3Over4]{3Over4}_2-3-5-6",
    },
    "[3Trên4]{3Trên4}_2-4-5-6": {
        vi: "[3Trên4]{3Trên4}_2-4-5-6",
        zh_CN: "[3Over4]{3Over4}_2-4-5-6",
    },
    "[3Trên4]{3Trên4}_3-4-5-6": {
        vi: "[3Trên4]{3Trên4}_3-4-5-6",
        zh_CN: "[3Over4]{3Over4}_3-4-5-6",
    },
    "[Đôi+1]{3Trên4}_1-2-3-4": {
        vi: "[Đôi+1]{3Trên4}_1-2-3-4",
        zh_CN: "[双+1]{3Over4}_1-2-3-4",
    },
    "[Đôi+1]{3Trên4}_1-2-3-5": {
        vi: "[Đôi+1]{3Trên4}_1-2-3-5",
        zh_CN: "[双+1]{3Over4}_1-2-3-5",
    },
    "[Đôi+1]{3Trên4}_1-2-3-6": {
        vi: "[Đôi+1]{3Trên4}_1-2-3-6",
        zh_CN: "[双+1]{3Over4}_1-2-3-6",
    },
    "[Đôi+1]{3Trên4}_1-2-4-5": {
        vi: "[Đôi+1]{3Trên4}_1-2-4-5",
        zh_CN: "[双+1]{3Over4}_1-2-4-5",
    },
    "[Đôi+1]{3Trên4}_1-2-4-6": {
        vi: "[Đôi+1]{3Trên4}_1-2-4-6",
        zh_CN: "[双+1]{3Over4}_1-2-4-6",
    },
    "[Đôi+1]{3Trên4}_1-2-5-6": {
        vi: "[Đôi+1]{3Trên4}_1-2-5-6",
        zh_CN: "[双+1]{3Over4}_1-2-5-6",
    },
    "[Đôi+1]{3Trên4}_1-3-4-5": {
        vi: "[Đôi+1]{3Trên4}_1-3-4-5",
        zh_CN: "[双+1]{3Over4}_1-3-4-5",
    },
    "[Đôi+1]{3Trên4}_1-3-4-6": {
        vi: "[Đôi+1]{3Trên4}_1-3-4-6",
        zh_CN: "[双+1]{3Over4}_1-3-4-6",
    },
    "[Đôi+1]{3Trên4}_1-3-5-6": {
        vi: "[Đôi+1]{3Trên4}_1-3-5-6",
        zh_CN: "[双+1]{3Over4}_1-3-5-6",
    },
    "[Đôi+1]{3Trên4}_1-4-5-6": {
        vi: "[Đôi+1]{3Trên4}_1-4-5-6",
        zh_CN: "[双+1]{3Over4}_1-4-5-6",
    },
    "[Đôi+1]{3Trên4}_2-3-4-5": {
        vi: "[Đôi+1]{3Trên4}_2-3-4-5",
        zh_CN: "[双+1]{3Over4}_2-3-4-5",
    },
    "[Đôi+1]{3Trên4}_2-3-4-6": {
        vi: "[Đôi+1]{3Trên4}_2-3-4-6",
        zh_CN: "[双+1]{3Over4}_2-3-4-6",
    },
    "[Đôi+1]{3Trên4}_2-3-5-6": {
        vi: "[Đôi+1]{3Trên4}_2-3-5-6",
        zh_CN: "[双+1]{3Over4}_2-3-5-6",
    },
    "[Đôi+1]{3Trên4}_2-4-5-6": {
        vi: "[Đôi+1]{3Trên4}_2-4-5-6",
        zh_CN: "[双+1]{3Over4}_2-4-5-6",
    },
    "[Đôi+1]{3Trên4}_3-4-5-6": {
        vi: "[Đôi+1]{3Trên4}_3-4-5-6",
        zh_CN: "[双+1]{3Over4}_3-4-5-6",
    },
    "[3Trên5]{3Trên5/Tamcô}_1-2-3-4-5": {
        vi: "[3Trên5]{3Trên5/Tamcô}_1-2-3-4-5",
        zh_CN: "[3Over5]{3Over5/塔姆科}_1-2-3-4-5",
    },
    "[3Trên5]{3Trên5/Tamcô}_1-2-3-4-6": {
        vi: "[3Trên5]{3Trên5/Tamcô}_1-2-3-4-6",
        zh_CN: "[3Over5]{3Over5/塔姆科}_1-2-3-4-6",
    },
    "[3Trên5]{3Trên5/Tamcô}_1-2-3-5-6": {
        vi: "[3Trên5]{3Trên5/Tamcô}_1-2-3-5-6",
        zh_CN: "[3Over5]{3Over5/塔姆科}_1-2-3-5-6",
    },
    "[3Trên5]{3Trên5/Tamcô}_1-2-4-5-6": {
        vi: "[3Trên5]{3Trên5/Tamcô}_1-2-4-5-6",
        zh_CN: "[3Over5]{3Over5/塔姆科}_1-2-4-5-6",
    },
    "[3Trên5]{3Trên5/Tamcô}_1-3-4-5-6": {
        vi: "[3Trên5]{3Trên5/Tamcô}_1-3-4-5-6",
        zh_CN: "[3Over5]{3Over5/塔姆科}_1-3-4-5-6",
    },
    "[3Trên5]{3Trên5/Tamcô}_2-3-4-5-6": {
        vi: "[3Trên5]{3Trên5/Tamcô}_2-3-4-5-6",
        zh_CN: "[3Over5]{3Over5/塔姆科}_2-3-4-5-6",
    },
    "[TamCô/Sảnh/ĐãTrộnSố/ĐỏĐen]{3Trên5/Tamcô}_Đôi+1": {
        vi: "[TamCô/Sảnh/ĐãTrộnSố/ĐỏĐen]{3Trên5/Tamcô}_Đôi+1",
        zh_CN: "[TamCo/Lobby/Mixed Number/RedBlack]{3Over5/Tamco}_Double+1",
    },
    "[TamCô/Sảnh/ĐãTrộnSố/ĐỏĐen]{3Trên5/Tamcô}_TamCô": {
        vi: "[TamCô/Sảnh/ĐãTrộnSố/ĐỏĐen]{3Trên5/Tamcô}_TamCô",
        zh_CN: "[TamCo/Lobby/混合号码/RedBlack]{3Over5/TamCo}_TamCo",
    },
    "[TamCô/Sảnh/ĐãTrộnSố/ĐỏĐen]{3Trên5/Tamcô}_Sảnh": {
        vi: "[TamCô/Sảnh/ĐãTrộnSố/ĐỏĐen]{3Trên5/Tamcô}_Sảnh",
        zh_CN: "[TamCo/Lobby/Mixed Number/RedBlack]{3Over5/Tamco}_Lobby",
    },
    "[CấpSốNhân]{Thậpcẩm}_1": {
        vi: "[CấpSốNhân]{Thậpcẩm}_1",
        zh_CN: "[乘数]{小数}_1",
    },
    "[CấpSốNhân]{Thậpcẩm}_2": {
        vi: "[CấpSốNhân]{Thậpcẩm}_2",
        zh_CN: "[乘数]{小数}_2",
    },
    "[CấpSốNhân]{Thậpcẩm}_3": {
        vi: "[CấpSốNhân]{Thậpcẩm}_3",
        zh_CN: "[乘数]{小数}_3",
    },
    "[CấpSốNhân]{Thậpcẩm}_4": {
        vi: "[CấpSốNhân]{Thậpcẩm}_4",
        zh_CN: "[乘数]{小数}_4",
    },
    "[CấpSốNhân]{Thậpcẩm}_5": {
        vi: "[CấpSốNhân]{Thậpcẩm}_5",
        zh_CN: "[乘数]{小数}_5",
    },
    "[CấpSốNhân]{Thậpcẩm}_6": {
        vi: "[CấpSốNhân]{Thậpcẩm}_6",
        zh_CN: "[乘数]{小数}_6",
    },
    "[Đỏ&Đen]{Thậpcẩm}_ĐỏL": {
        vi: "[Đỏ&Đen]{Thậpcẩm}_ĐỏL",
        zh_CN: "[红&黑]{后裔}_RedL",
    },
    "[Đỏ&Đen]{Thậpcẩm}_ĐỏC": {
        vi: "[Đỏ&Đen]{Thậpcẩm}_ĐỏC",
        zh_CN: "[红&黑]{后裔}_红",
    },
    "[Đỏ&Đen]{Thậpcẩm}_ĐỏT": {
        vi: "[Đỏ&Đen]{Thậpcẩm}_ĐỏT",
        zh_CN: "[红&黑]{后裔}_红",
    },
    "[Đỏ&Đen]{Thậpcẩm}_ĐỏX": {
        vi: "[Đỏ&Đen]{Thậpcẩm}_ĐỏX",
        zh_CN: "[红&黑]{后裔}_RedX",
    },
    "[Đỏ&Đen]{Thậpcẩm}_ĐenL": {
        vi: "[Đỏ&Đen]{Thậpcẩm}_ĐenL",
        zh_CN: "[红&黑]{后裔}_BlackL",
    },
    "[Đỏ&Đen]{Thậpcẩm}_ĐenC": {
        vi: "[Đỏ&Đen]{Thậpcẩm}_ĐenC",
        zh_CN: "[红&黑]{后裔}_BlackC",
    },
    "[Đỏ&Đen]{Thậpcẩm}_ĐenT": {
        vi: "[Đỏ&Đen]{Thậpcẩm}_ĐenT",
        zh_CN: "[红&黑]{后裔}_BlackT",
    },
    "[Đỏ&Đen]{Thậpcẩm}_ĐenX": {
        vi: "[Đỏ&Đen]{Thậpcẩm}_ĐenX",
        zh_CN: "[红&黑]{后裔}_BlackX",
    },
    "[CáchBiệt]{Thậpcẩm}_CáchBiệt1": {
        vi: "[CáchBiệt]{Thậpcẩm}_CáchBiệt1",
        zh_CN: "[区别]{十二月}_区别1",
    },
    "[CáchBiệt]{Thậpcẩm}_CáchBiệt2": {
        vi: "[CáchBiệt]{Thậpcẩm}_CáchBiệt2",
        zh_CN: "[区别]{December}_Difference2",
    },
    "[CáchBiệt]{Thậpcẩm}_CáchBiệt3": {
        vi: "[CáchBiệt]{Thậpcẩm}_CáchBiệt3",
        zh_CN: "[区别]{December}_Difference3",
    },
    "[CáchBiệt]{Thậpcẩm}_CáchBiệt4": {
        vi: "[CáchBiệt]{Thậpcẩm}_CáchBiệt4",
        zh_CN: "[区别]{十二月}_Distinction4",
    },
    "[CáchBiệt]{Thậpcẩm}_CáchBiệt5": {
        vi: "[CáchBiệt]{Thậpcẩm}_CáchBiệt5",
        zh_CN: "[区别]{December}_Difference5",
    },
    "Mặc định:": {
        vi: "Mặc định:",
        zh_CN: "默认：",
    },
    "Thêm tài khoản": {
        vi: "Thêm tài khoản",
        zh_CN: "更多帐号",
    },
    "Tẩt cả trạng thái": {
        vi: "Tẩt cả trạng thái",
        zh_CN: "所有状态",
    },
    Sửa: {
        vi: "Sửa",
        zh_CN: "使固定",
    },
    "ID:": {
        vi: "ID:",
        zh_CN: "ID：",
    },
    "Mã giới thiệu:": {
        vi: "Mã giới thiệu:",
        zh_CN: "推荐码：",
    },
    "Ngày tạo:": {
        vi: "Ngày tạo:",
        zh_CN: "创建日期：",
    },
    "Cập nhật gần nhất:": {
        vi: "Cập nhật gần nhất:",
        zh_CN: "最近更新：",
    },
    "Họ tên:": {
        vi: "Họ tên:",
        zh_CN: "姓名：",
    },
    "Email:": {
        vi: "Email:",
        zh_CN: "电子邮件：",
    },
    "Số điện thoại:": {
        vi: "Số điện thoại:",
        zh_CN: "电话号码：",
    },
    "Địa chỉ:": {
        vi: "Địa chỉ:",
        zh_CN: "地址：",
    },
    "Giới tính:": {
        vi: "Giới tính:",
        zh_CN: "性别：",
    },
    "Ngày sinh:": {
        vi: "Ngày sinh:",
        zh_CN: "出生日期：",
    },
    "Phiên đăng nhập:": {
        vi: "Phiên đăng nhập:",
        zh_CN: "登录会话：",
    },
    "T/gian hết hạn:": {
        vi: "T/gian hết hạn:",
        zh_CN: "过期时间：",
    },
    "Đăng nhập lần cuối:": {
        vi: "Đăng nhập lần cuối:",
        zh_CN: "上次登录：",
    },
    "Các trường gắn dấu * là bắt buộc.": {
        vi: "Các trường gắn dấu * là bắt buộc.",
        zh_CN: "标有*为必填项。",
    },
    "Bắt buộc, từ 3 tới 30 ký tự": {
        vi: "Bắt buộc, từ 3 tới 30 ký tự",
        zh_CN: "必填，3 到 30 个字符",
    },
    "Tên đăng nhập viết không dấu, cho phép chữ cái, chữ số và dấu gạch dưới (_). VD: nguyenvana_123, ...":
        {
            vi: "Tên đăng nhập viết không dấu, cho phép chữ cái, chữ số và dấu gạch dưới (_). VD: nguyenvana_123, ...",
            zh_CN: "用户名必须不带重音符号，允许字母、数字和下划线 (_)。 例如：nguyenvana_123，...",
        },
    "Mật khẩu": {
        vi: "Mật khẩu",
        zh_CN: "密码",
    },
    "Bắt buộc, từ 6 tới 30 ký tự": {
        vi: "Bắt buộc, từ 6 tới 30 ký tự",
        zh_CN: "必填，6 到 30 个字符",
    },
    "Mật khẩu nên có đầy đủ chữ số, chữ cái in hoa, in thường để đảm bảo mức độ bảo mật.":
        {
            vi: "Mật khẩu nên có đầy đủ chữ số, chữ cái in hoa, in thường để đảm bảo mức độ bảo mật.",
            zh_CN: "密码必须是完整的数字，大小写字母，以保证安全级别。",
        },
    "Nhắc lại mật khẩu": {
        vi: "Nhắc lại mật khẩu",
        zh_CN: "重复输入密码",
    },
    "Mật khẩu nhắc lại cần giống mật khẩu.": {
        vi: "Mật khẩu nhắc lại cần giống mật khẩu.",
        zh_CN: "重复密码应与密码相同。",
    },
    "Họ tên": {
        vi: "Họ tên",
        zh_CN: "全名",
    },
    "Tối đa 32 ký tự": {
        vi: "Tối đa 32 ký tự",
        zh_CN: "最多 32 个字符",
    },
    "Tên tài khoản, nên là tên thật. VD: Nguyễn Văn A, ...": {
        vi: "Tên tài khoản, nên là tên thật. VD: Nguyễn Văn A, ...",
        zh_CN: "账户名，应为实名。 例子：Nguyen Van A, ...",
    },
    "Cộng/Trừ tiền": {
        vi: "Cộng/Trừ tiền",
        zh_CN: "加/减钱",
    },
    "+/- Tiền": {
        vi: "+/- Tiền",
        zh_CN: "+/- 金钱",
    },
    "Cược mở": {
        vi: "Cược mở",
        zh_CN: "公开投注",
    },
    "Số tiền giao dịch:": {
        vi: "Số tiền giao dịch:",
        zh_CN: "交易金额：",
    },
    "Loại giao dịch:": {
        vi: "Loại giao dịch:",
        zh_CN: "交易类型：",
    },
    "Số dư trước GD:": {
        vi: "Số dư trước GD:",
        zh_CN: "交易前余额：",
    },
    "Số dư sau GD:": {
        vi: "Số dư sau GD:",
        zh_CN: "交易后余额：",
    },
    "Thời gian:": {
        vi: "Thời gian:",
        zh_CN: "时间：",
    },
    "Nội dung:": {
        vi: "Nội dung:",
        zh_CN: "内容：",
    },
    "Xác nhận xóa cược ?": {
        vi: "Xác nhận xóa cược ?",
        zh_CN: "确认注销？",
    },
    "Xác nhận xóa giao dịch ?": {
        vi: "Xác nhận xóa giao dịch ?",
        zh_CN: "确认交易删除？",
    },
    "Tỉ lệ chung:": {
        vi: "Tỉ lệ chung:",
        zh_CN: "综合率：",
    },
    "Chọn trò chơi": {
        vi: "Chọn trò chơi",
        zh_CN: "选择一个游戏",
    },
    "Chọn trò chơi (bắt buộc)": {
        vi: "Chọn trò chơi (bắt buộc)",
        zh_CN: "选择游戏（必填）",
    },
    "Chọn loại cược": {
        vi: "Chọn loại cược",
        zh_CN: "选择投注类型",
    },
    "Chọn loại cược (bắt buộc)": {
        vi: "Chọn loại cược (bắt buộc)",
        zh_CN: "选择投注类型（必填）",
    },
    "Nhập tỉ lệ cược mới": {
        vi: "Nhập tỉ lệ cược mới",
        zh_CN: "输入新赔率",
    },
    "Nhập tỉ lệ cược mới...": {
        vi: "Nhập tỉ lệ cược mới...",
        zh_CN: "输入新赔率...",
    },
    "Xác nhận xóa tỉ lệ cược của": {
        vi: "Xác nhận xóa tỉ lệ cược của",
        zh_CN: "确认移除赔率",
    },
    "Ảnh đại diện": {
        vi: "Ảnh đại diện",
        zh_CN: "头像",
    },
    "Bắt buộc, từ 3 tới 32 ký tự": {
        vi: "Bắt buộc, từ 3 tới 32 ký tự",
        zh_CN: "必填，3 到 32 个字符",
    },
    "Từ 6 tới 30 ký tự": {
        vi: "Từ 6 tới 30 ký tự",
        zh_CN: "从 6 到 30 个字符",
    },
    "Tên đăng nhập không thể thay đổi.": {
        vi: "Tên đăng nhập không thể thay đổi.",
        zh_CN: "用户名不能更改。",
    },
    "Tối đa 100 ký tự": {
        vi: "Tối đa 100 ký tự",
        zh_CN: "最多 100 个字符",
    },
    "Mã giới thiệu không thể thay đổi. Mã giới thiệu dùng để mời bạn bè tham gia cùng":
        {
            vi: "Mã giới thiệu không thể thay đổi. Mã giới thiệu dùng để mời bạn bè tham gia cùng",
            zh_CN: "无法更改推荐代码。 用于邀请朋友加入的推荐代码",
        },
    "Nhập email": {
        vi: "Nhập email",
        zh_CN: "输入电子邮件",
    },
    "Nhập số điện thoại": {
        vi: "Nhập số điện thoại",
        zh_CN: "输入你的电话号码",
    },
    "Địa chỉ": {
        vi: "Địa chỉ",
        zh_CN: "地址",
    },
    "Nhập địa chỉ": {
        vi: "Nhập địa chỉ",
        zh_CN: "输入地址",
    },
    "Ngày sinh": {
        vi: "Ngày sinh",
        zh_CN: "出生日期",
    },
    "Chọn ngày sinh": {
        vi: "Chọn ngày sinh",
        zh_CN: "选择您的出生日期",
    },
    "Giới tính": {
        vi: "Giới tính",
        zh_CN: "性别",
    },
    "Chọn giới tính": {
        vi: "Chọn giới tính",
        zh_CN: "选择你的性别",
    },
    "Thông tin thêm (Ghi chú)": {
        vi: "Thông tin thêm (Ghi chú)",
        zh_CN: "附加信息（注）",
    },
    "Nhập ghi chú": {
        vi: "Nhập ghi chú",
        zh_CN: "输入备注",
    },
    "Trạng thái tài khoản": {
        vi: "Trạng thái tài khoản",
        zh_CN: "帐户状态",
    },
    "Chọn trạng thái": {
        vi: "Chọn trạng thái",
        zh_CN: "选择状态",
    },
    "Số dư hiện tại:": {
        vi: "Số dư hiện tại:",
        zh_CN: "当前余额：",
    },
    "Cấp trên / Đại lý:": {
        vi: "Cấp trên / Đại lý:",
        zh_CN: "高级/代理人：",
    },
    "Ngân hàng:": {
        vi: "Ngân hàng:",
        zh_CN: "银行：",
    },
    "Chủ tài khoản:": {
        vi: "Chủ tài khoản:",
        zh_CN: "账户持有人：",
    },
    "Số tài khoản:": {
        vi: "Số tài khoản:",
        zh_CN: "帐号：",
    },
    "Tổng nạp:": {
        vi: "Tổng nạp:",
        zh_CN: "总费用：",
    },
    "Tổng KM nạp:": {
        vi: "Tổng KM nạp:",
        zh_CN: "装载总里程：",
    },
    "Tổng rút:": {
        vi: "Tổng rút:",
        zh_CN: "概括：",
    },
    "Tổng cược:": {
        vi: "Tổng cược:",
        zh_CN: "总赌注：",
    },
    "Tổng thắng:": {
        vi: "Tổng thắng:",
        zh_CN: "总胜率：",
    },
    "Tổng BQT cộng tiền:": {
        vi: "Tổng BQT cộng tiền:",
        zh_CN: "总 BQT 加上钱：",
    },
    "Tổng BQT trừ tiền:": {
        vi: "Tổng BQT trừ tiền:",
        zh_CN: "总扣款：",
    },
    "Tổng hoàn tiền rút:": {
        vi: "Tổng hoàn tiền rút:",
        zh_CN: "提款总额：",
    },
    "Cấp trên trực tiếp": {
        vi: "Cấp trên trực tiếp",
        zh_CN: "直属上级",
    },
    "Cấp trên trực tiếp không thể thay đổi. Cấp trên trực tiếp là tài khoản đã giới thiệu thành công tài khoản này!":
        {
            vi: "Cấp trên trực tiếp không thể thay đổi. Cấp trên trực tiếp là tài khoản đã giới thiệu thành công tài khoản này!",
            zh_CN: "直属上级不能更改。 直接上级是成功推荐此账号的账号！",
        },
    "Mật khẩu rút tiền": {
        vi: "Mật khẩu rút tiền",
        zh_CN: "提现密码",
    },
    "Từ 3 tới 100 ký tự": {
        vi: "Từ 3 tới 100 ký tự",
        zh_CN: "3 到 100 个字符",
    },
    "Chọn cấp độ": {
        vi: "Chọn cấp độ",
        zh_CN: "选择等级",
    },
    "Chọn ngân hàng": {
        vi: "Chọn ngân hàng",
        zh_CN: "选择一家银行",
    },
    "Xác nhận xóa tài khoản": {
        vi: "Xác nhận xóa tài khoản",
        zh_CN: "确认帐户删除",
    },
    "Chọn hành động:": {
        vi: "Chọn hành động:",
        zh_CN: "选择动作：",
    },
    "Cộng Tiền": {
        vi: "Cộng Tiền",
        zh_CN: "公款",
    },
    "Trừ Tiền": {
        vi: "Trừ Tiền",
        zh_CN: "扣除",
    },
    "Nhập số tiền": {
        vi: "Nhập số tiền",
        zh_CN: "输入金额",
    },
    "Nhập số tiền...": {
        vi: "Nhập số tiền...",
        zh_CN: "输入金额...",
    },
    "Ghi chú": {
        vi: "Ghi chú",
        zh_CN: "笔记",
    },
    "Cược đóng": {
        vi: "Cược đóng",
        zh_CN: "关闭投注",
    },
    "Thêm yêu cầu nạp tiền": {
        vi: "Thêm yêu cầu nạp tiền",
        zh_CN: "添加存款请求",
    },
    "Tìm kiếm khách hàng cần nạp tiền...": {
        vi: "Tìm kiếm khách hàng cần nạp tiền...",
        zh_CN: "找客户充值...",
    },
    "Nhập số tiền nạp": {
        vi: "Nhập số tiền nạp",
        zh_CN: "输入存款金额",
    },
    "Nhập số tiền KM (nếu có)": {
        vi: "Nhập số tiền KM (nếu có)",
        zh_CN: "输入公里数（如果有）",
    },
    "Tẩt cả xử lý": {
        vi: "Tẩt cả xử lý",
        zh_CN: "所有加工",
    },
    "Xử lý yêu cầu nạp tiền": {
        vi: "Xử lý yêu cầu nạp tiền",
        zh_CN: "充值请求处理",
    },
    "*Ngân hàng hệ thống (nhận tiền):": {
        vi: "*Ngân hàng hệ thống (nhận tiền):",
        zh_CN: "*银行系统（收款）：",
    },
    "Xử lý": {
        vi: "Xử lý",
        zh_CN: "处理",
    },
    "Ngân hàng hệ thống (nhận tiền)": {
        vi: "Ngân hàng hệ thống (nhận tiền)",
        zh_CN: "系统银行（收款）",
    },
    "Chọn ngân hàng (bắt buộc)": {
        vi: "Chọn ngân hàng (bắt buộc)",
        zh_CN: "选择银行（必填）",
    },
    "Trạng thái: ": {
        vi: "Trạng thái: ",
        zh_CN: "地位：",
    },
    "Tình trạng:": {
        vi: "Tình trạng:",
        zh_CN: "地位：",
    },
    '*Chú ý: BQT check tài khoản ngân hàng. Bấm "Phê duyệt" nếu khách hàng đã duyệt tới STK của hệ thống (nhận tiền). Sau khi xác nhận, số tiền sẽ được cộng vào tài khoản của khách hàng trên hệ thống. Ngược lại bấm "Không duyệt". Yêu cầu nạp sẽ bị Hủy.':
        {
            vi: '*Chú ý: BQT check tài khoản ngân hàng. Bấm "Phê duyệt" nếu khách hàng đã duyệt tới STK của hệ thống (nhận tiền). Sau khi xác nhận, số tiền sẽ được cộng vào tài khoản zh_CNa khách hàng trên hệ thống. Ngược lại bấm "Không duyệt". Yêu cầu nạp sẽ bị Hủy.',
            cn: "*注意：BQT 检查银行账户。 如果客户已经向系统的STK转账（收款），点击“同意”。 确认后，金额将添加到客户在系统上的帐户中。 否则单击“不浏览”。 存款请求将被取消。",
        },
    "Nội dung (Phê duyệt)": {
        vi: "Nội dung (Phê duyệt)",
        zh_CN: "内容（批准）",
    },
    "Nhập nội dung nếu phê duyệt...": {
        vi: "Nhập nội dung nếu phê duyệt...",
        zh_CN: "如果获得批准，请输入内容...",
    },
    "Nội dung (Phê duyệt) KM": {
        vi: "Nội dung (Phê duyệt) KM",
        zh_CN: "内容（批准）KM",
    },
    "Nhập nội dung KM nếu phê duyệt...": {
        vi: "Nhập nội dung KM nếu phê duyệt...",
        zh_CN: "如果获得批准，请输入 KM 内容...",
    },
    "Nội dung (Không duyệt):": {
        vi: "Nội dung (Không duyệt):",
        zh_CN: "内容（请勿浏览）:",
    },
    "Nội dung (Không duyệt)": {
        vi: "Nội dung (Không duyệt)",
        zh_CN: "内容（请勿浏览）",
    },
    "Nội dung (Hoàn tiền rút):": {
        vi: "Nội dung (Hoàn tiền rút):",
        zh_CN: "内容（退款取款）：",
    },
    "Nhập nội dung nếu không duyệt...": {
        vi: "Nhập nội dung nếu không duyệt...",
        zh_CN: "如果不浏览请输入内容...",
    },
    "Cập nhật": {
        vi: "Cập nhật",
        zh_CN: "更新",
    },
    'Xác nhận "Phê duyệt" yêu cầu nạp ?': {
        vi: 'Xác nhận "Phê duyệt" yêu cầu nạp ?',
        zh_CN: "确认加载请求的“批准”？",
    },
    'Xác nhận "Không duyệt" yêu cầu nạp ?': {
        vi: 'Xác nhận "Không duyệt" yêu cầu nạp ?',
        zh_CN: "确认“不批准”加载请求？",
    },
    "Xác nhận xóa yêu cầu nạp ?": {
        vi: "Xác nhận xóa yêu cầu nạp ?",
        zh_CN: "确认删除加载请求？",
    },
    "Thêm yêu cầu rút tiền": {
        vi: "Thêm yêu cầu rút tiền",
        zh_CN: "添加取款请求",
    },
    "Tìm kiếm khách hàng cần rút tiền...": {
        vi: "Tìm kiếm khách hàng cần rút tiền...",
        zh_CN: "找客户取钱...",
    },
    "Nhập số tiền rút": {
        vi: "Nhập số tiền rút",
        zh_CN: "输入取款金额",
    },
    "Nội dung (Trừ tiền rút)": {
        vi: "Nội dung (Trừ tiền rút)",
        zh_CN: "内容（取款除外）",
    },
    "Nhập nội dung trừ tiền rút...": {
        vi: "Nhập nội dung trừ tiền rút...",
        zh_CN: "输入内容减去提款...",
    },
    "Xử lý yêu cầu rút tiền": {
        vi: "Xử lý yêu cầu rút tiền",
        zh_CN: "处理取款请求",
    },
    "*Ngân hàng thụ hưởng của khách (nhận tiền):": {
        vi: "*Ngân hàng thụ hưởng của khách (nhận tiền):",
        zh_CN: "*客户收款银行（收款）：",
    },
    "Số dư trước giao dịch rút:": {
        vi: "Số dư trước giao dịch rút:",
        zh_CN: "取款前余额：",
    },
    "Số dư sau giao dịch rút:": {
        vi: "Số dư sau giao dịch rút:",
        zh_CN: "取款后余额：",
    },
    "Số tiền rút:": {
        vi: "Số tiền rút:",
        zh_CN: "提款金额：",
    },
    "Xem lại": {
        vi: "Xem lại",
        zh_CN: "审查",
    },
    STK: {
        vi: "STK",
        zh_CN: "STK",
    },
    '*Chú ý: BQT check tài khoản ngân hàng thụ hưởng của khách và thực hiện chuyển tiền. Bấm "Phê duyệt" nếu BQT đã duyệt cho khách. Sau khi xác nhận, Yêu cầu rút sẽ được duyệt. Ngược lại bấm "Không duyệt". Yêu cầu rút sẽ bị Hủy, tiền rút đã trừ trên hệ thống của khách hàng sẽ được hoàn lại.':
        {
            vi: '*Chú ý: BQT check tài khoản ngân hàng thụ hưởng của khách và thực hiện chuyển tiền. Bấm "Phê duyệt" nếu BQT đã duyệt cho khách. Sau khi xác nhận, Yêu cầu rút sẽ được zh_CNyệt. Ngược lại bấm "Không duyệt". Yêu cầu rút sẽ bị Hủy, tiền rút đã trừ trên hệ thống của khách hàng sẽ được hoàn lại.',
            cn: "*注意：BQT 检查收款人的银行账户并进行汇款。 如果 BQT 已经将钱转给客户，请单击“批准”。 一旦确认，提款申请将获得批准。 否则单击“不浏览”。 取款请求将被取消，从客户系统中扣除的取款金额将被退还。",
        },
    "Nội dung (Hoàn tiền rút)": {
        vi: "Nội dung (Hoàn tiền rút)",
        zh_CN: "内容（退款提款）",
    },
    "Nhập nội dung hoàn tiền rút...": {
        vi: "Nhập nội dung hoàn tiền rút...",
        zh_CN: "输入取款退款...",
    },
    'Xác nhận "Không duyệt" yêu cầu rút ?': {
        vi: 'Xác nhận "Không duyệt" yêu cầu rút ?',
        zh_CN: "确认“不批准”取款请求？",
    },
    'Xác nhận "Phê duyệt" yêu cầu rút ?': {
        vi: 'Xác nhận "Phê duyệt" yêu cầu rút ?',
        zh_CN: "确认提款请求的“批准” ?",
    },
    "Xác nhận xóa yêu cầu rút ?": {
        vi: "Xác nhận xóa yêu cầu rút ?",
        zh_CN: "确认删除取款请求？",
    },
    "Rút tiền tài khoản...": {
        vi: "Rút tiền tài khoản...",
        zh_CN: "从账户提款...",
    },
    "Kết quả: Kết quả cho mỗi lần rút được hình thành theo giá trị của 6 xúc xắc (từ 1 ~ 6)":
        {
            vi: "Kết quả: Kết quả cho mỗi lần rút được hình thành theo giá trị của 6 xúc xắc (từ 1 ~ 6)",
            zh_CN: "结果：每次抽奖的结果根据6个骰子的点数（从1~6）组成",
        },
    "Tìm số kỳ...": {
        vi: "Tìm số kỳ...",
        zh_CN: "求周期数...",
    },
    "Chọn KQ mới": {
        vi: "Chọn KQ mới",
        zh_CN: "选择新结果",
    },
    cược: {
        vi: "cược",
        zh_CN: "赌注",
    },
    "T/gian bắt đầu:": {
        vi: "T/gian bắt đầu:",
        zh_CN: "开始时间：",
    },
    "T/gian kết thúc:": {
        vi: "T/gian kết thúc:",
        zh_CN: "时间结束：",
    },
    "[Chính Thức] Cược Tổng:": {
        vi: "[Chính Thức] Cược Tổng:",
        zh_CN: "[官方] 总赌注：",
    },
    "[Chính Thức] Cược Tam cô chung": {
        vi: "[Chính Thức] Cược Tam cô chung",
        zh_CN: "[官方] 一起押注Tam Co",
    },
    "[Chính Thức] Cược Tam cô": {
        vi: "[Chính Thức] Cược Tam cô",
        zh_CN: "[官方] 三重投注",
    },
    "[Chính Thức] Cược 3 số khác nhau": {
        vi: "[Chính Thức] Cược 3 số khác nhau",
        zh_CN: "[官方] 投注 3 个不同的数字",
    },
    "[Chính Thức] Cược Tổng sảnh": {
        vi: "[Chính Thức] Cược Tổng sảnh",
        zh_CN: "[官方] 总投注",
    },
    "[Chính Thức] Cược Đôi": {
        vi: "[Chính Thức] Cược Đôi",
        zh_CN: "[官方] 双倍投注",
    },
    "[Chính Thức] Cược Đôi + 1": {
        vi: "[Chính Thức] Cược Đôi + 1",
        zh_CN: "【官方】双打+1",
    },
    "[Chính Thức] Cược 2 khác nhau": {
        vi: "[Chính Thức] Cược 2 khác nhau",
        zh_CN: "[官方] 2 种不同的投注",
    },
    "[Cổ điển] Cược Khác": {
        vi: "[Cổ điển] Cược Khác",
        zh_CN: "[经典] 其他投注",
    },
    "[Cổ điển] Cược 3 trên 4": {
        vi: "[Cổ điển] Cược 3 trên 4",
        zh_CN: "【经典】4选3",
    },
    "[Cổ điển] Cược 3 trên 5 / Tam cô": {
        vi: "[Cổ điển] Cược 3 trên 5 / Tam cô",
        zh_CN: "[经典] 3 out of 5 / Tam Co",
    },
    "[Cổ điển] Cược Thập cẩm": {
        vi: "[Cổ điển] Cược Thập cẩm",
        zh_CN: "【经典】混注",
    },
    "Thêm ngân hàng": {
        vi: "Thêm ngân hàng",
        zh_CN: "添加银行",
    },
    "Tìm tên chủ tài khoản...": {
        vi: "Tìm tên chủ tài khoản...",
        zh_CN: "查找帐户持有人姓名...",
    },
    "Tất cả ngân hàng": {
        vi: "Tất cả ngân hàng",
        zh_CN: "所有银行",
    },
    "Tên ngân hàng:": {
        vi: "Tên ngân hàng:",
        zh_CN: "银行名称：",
    },
    "Tiền đã nạp:": {
        vi: "Tiền đã nạp:",
        zh_CN: "存款：",
    },
    "Trạng thái:": {
        vi: "Trạng thái:",
        zh_CN: "地位：",
    },
    "T/gian tạo:": {
        vi: "T/gian tạo:",
        zh_CN: "创建时间/时间：",
    },
    "T/gian cập nhật gần nhất:": {
        vi: "T/gian cập nhật gần nhất:",
        zh_CN: "最后更新日期/时间：",
    },
    "Bắt buộc, từ 3 tới 100 ký tự": {
        vi: "Bắt buộc, từ 3 tới 100 ký tự",
        zh_CN: "必填，3 到 100 个字符",
    },
    "Xác nhận xóa ngân hàng ?": {
        vi: "Xác nhận xóa ngân hàng ?",
        zh_CN: "确认删除银行？",
    },
    "Tên tài khoản, nên là tên thật. VD: Nguyễn Văn A,...": {
        vi: "Tên tài khoản, nên là tên thật. VD: Nguyễn Văn A,...",
        zh_CN: "账户名，应为实名。 示例：Nguyen Van A,...",
    },
    "Chọn vai trò": {
        vi: "Chọn vai trò",
        zh_CN: "选择角色",
    },
    "Chọn vai trò cho tài khoản này.": {
        vi: "Chọn vai trò cho tài khoản này.",
        zh_CN: "为此帐户选择一个角色。",
    },
    "Thêm vai trò": {
        vi: "Thêm vai trò",
        zh_CN: "更多角色",
    },
    "Tìm tên vai trò...": {
        vi: "Tìm tên vai trò...",
        zh_CN: "查找角色名称...",
    },
    "Tên vai trò:": {
        vi: "Tên vai trò:",
        zh_CN: "角色名称：",
    },
    "Quyền được cấp": {
        vi: "Quyền được cấp",
        zh_CN: "许可授予",
    },
    "Thành viên nhóm": {
        vi: "Thành viên nhóm",
        zh_CN: "队员",
    },
    "Bắt buộc, tối đa 191 ký tự": {
        vi: "Bắt buộc, tối đa 191 ký tự",
        zh_CN: "必填，最多 191 个字符",
    },
    "Cấp quyền": {
        vi: "Cấp quyền",
        zh_CN: "授予权限",
    },
    "Chọn quyền hạn cho vai trò.": {
        vi: "Chọn quyền hạn cho vai trò.",
        zh_CN: "选择角色的权限。",
    },
    "Xác nhận xóa vai trò": {
        vi: "Xác nhận xóa vai trò",
        zh_CN: "确认角色删除",
    },
    "Cập nhật quyền": {
        vi: "Cập nhật quyền",
        zh_CN: "更新权限",
    },
    "Tìm tên quyền...": {
        vi: "Tìm tên quyền...",
        zh_CN: "查找权限...",
    },
    "Tên quyền:": {
        vi: "Tên quyền:",
        zh_CN: "权限名称：",
    },
    "Xác nhận cập nhật quyền hệ thống ?": {
        vi: "Xác nhận cập nhật quyền hệ thống ?",
        zh_CN: "确认系统权限更新？",
    },
    "Đã sao chép !": {
        vi: "Đã sao chép !",
        zh_CN: "已复制！",
    },
    "Không có dữ liệu !": {
        vi: "Không có dữ liệu !",
        zh_CN: "没有数据 ！",
    },
    "Nội dung (Phê duyệt):": {
        vi: "Nội dung (Phê duyệt):",
        zh_CN: "内容（批准）：",
    },
    "Nội dung (Phê duyệt) KM:": {
        vi: "Nội dung (Phê duyệt) KM:",
        zh_CN: "内容（审批）推广：",
    },
    "(Admin / Đại lý) xử lý giao dịch:": {
        vi: "(Admin / Đại lý) xử lý giao dịch:",
        zh_CN: "（管理员/代理）处理交易：",
    },
    "Thời gian xử lý:": {
        vi: "Thời gian xử lý:",
        zh_CN: "处理时间：",
    },
    "KH đã nhận:": {
        vi: "KH đã nhận:",
        zh_CN: "客户收到：",
    },
    "Nạp tiền thành công ...": {
        vi: "Nạp tiền thành công ...",
        zh_CN: "充值成功...",
    },
    "Khuyến mại nạp tiền ...": {
        vi: "Khuyến mại nạp tiền ...",
        zh_CN: "充值促销...",
    },
    "Số dư trước giao dịch nạp:": {
        vi: "Số dư trước giao dịch nạp:",
        zh_CN: "入金交易前余额：",
    },
    "Số dư sau giao dịch nạp:": {
        vi: "Số dư sau giao dịch nạp:",
        zh_CN: "存款交易后的余额：",
    },
    "Số tiền nạp:": {
        vi: "Số tiền nạp:",
        zh_CN: "存款金额：",
    },
    "Số tiền KM: ": {
        vi: "Số tiền KM: ",
        zh_CN: "公里数：",
    },
    "Số tiền rút KH đã bị trừ:": {
        vi: "Số tiền rút KH đã bị trừ:",
        zh_CN: "扣除的客户提款金额：",
    },
    "Rút tiền thành công ...": {
        vi: "Rút tiền thành công ...",
        zh_CN: "提款成功...",
    },
    "Rút tiền thất bại ...": {
        vi: "Rút tiền thất bại ...",
        zh_CN: "提款失败...",
    },
    "Hoàn tiền rút...": {
        vi: "Hoàn tiền rút...",
        zh_CN: "返现提现...",
    },
    "(Đại lý)": {
        vi: "(Đại lý)",
        zh_CN: "（机构）",
    },
    "Số tiền rút đã hoàn lại:": {
        vi: "Số tiền rút đã hoàn lại:",
        zh_CN: "可退取款金额：",
    },
    "Nạp tiền thất bại ...": {
        vi: "Nạp tiền thất bại ...",
        zh_CN: "充值失败...",
    },
    "Tiếp theo": {
        vi: "Tiếp theo",
        zh_CN: "下一个",
    },
    "Kết thúc": {
        vi: "Kết thúc",
        zh_CN: "结尾",
    },
    "Cấp dưới trực tiếp (F1):": {
        vi: "Cấp dưới trực tiếp (F1):",
        zh_CN: "直属下属（F1）：",
    },
    "Cấp dưới (F2):": {
        vi: "Cấp dưới (F2):",
        zh_CN: "下属（F2）：",
    },
    "Cấp dưới (F3):": {
        vi: "Cấp dưới (F3):",
        zh_CN: "下属（F3）：",
    },
    "Không xác định": {
        vi: "Không xác định",
        zh_CN: "未知",
    },
    "Bạn có thực sự muốn đăng xuất ? ": {
        vi: "Bạn có thực sự muốn đăng xuất ? ",
        zh_CN: "您真的要退出吗？",
    },
    "Đăng nhập": {
        vi: "Đăng nhập",
        zh_CN: "登录",
    },
    "Kỳ:": {
        vi: "Kỳ:",
        zh_CN: "时期：",
    },
    Thắng: {
        vi: "Thắng",
        zh_CN: "赢",
    },
    Thua: {
        vi: "Thua",
        zh_CN: "失去",
    },
    "Chờ KQ": {
        vi: "Chờ KQ",
        zh_CN: "等待结果",
    },
    "Đã trả": {
        vi: "Đã trả",
        zh_CN: "有薪酬的",
    },
};

export default Translations;
