import Login from "@/sceens/Login";
import { trans } from "@/app/Lang";
import Home from "@/sceens/Home";
import Register from "@/sceens/Register";
import Info from "@/sceens/Info";
import Withdraw from "@/sceens/Withdraw";
import WithdrawHistory from "@/sceens/WithdrawHistory";
import Rule from "@/sceens/Rule";
import OrderHistory from "@/sceens/OrderHistory";

const Router = [
    {
        title: trans("Đăng nhập"),
        icon: undefined,
        name: "login",
        path: "login",
        component: Login,
        redirect: undefined,
        private: false,
        childrens: undefined,
    },
    {
        title: trans("Đăng ký"),
        icon: undefined,
        name: "register",
        path: "register",
        component: Register,
        redirect: undefined,
        private: false,
        childrens: undefined,
    },
    {
        title: trans("Trung tâm hoạt động"),
        icon: undefined,
        name: "home",
        path: "home",
        component: Home,
        redirect: undefined,
        private: true,
        childrens: undefined,
    },
    {
        title: trans("Thông tin cá nhân"),
        icon: undefined,
        name: "info",
        path: "info",
        component: Info,
        redirect: undefined,
        private: true,
        childrens: undefined,
    },
    {
        title: trans("Hồ sơ bình chọn"),
        icon: undefined,
        name: "order",
        path: "order",
        component: OrderHistory,
        redirect: undefined,
        private: true,
        childrens: undefined,
    },
    {
        title: trans("Rút tiền"),
        icon: undefined,
        name: "withdraw",
        path: "withdraw",
        component: Withdraw,
        redirect: undefined,
        private: true,
        childrens: [
            {
                title: trans("Hồ sơ rút tiền"),
                icon: undefined,
                name: "history",
                path: "history",
                component: WithdrawHistory,
                redirect: undefined,
                private: true,
                childrens: undefined,
            },
        ],
    },
    {
        title: trans("Nạp tiền"),
        icon: undefined,
        name: "recharge",
        path: "recharge",
        component: Home,
        redirect: undefined,
        private: true,
        childrens: [
            {
                title: trans("Hồ sơ nạp tiền"),
                icon: undefined,
                name: "history",
                path: "history",
                component: Home,
                redirect: undefined,
                private: true,
                childrens: undefined,
            },
        ],
    },
    {
        title: trans("Quy tắc hoạt động"),
        icon: undefined,
        name: "rules",
        path: "rules",
        component: Rule,
        redirect: undefined,
        private: true,
        childrens: undefined,
    },
];
export function routerMap(
    routes = Router,
    path = undefined,
    result = [],
    key = undefined
) {
    routes &&
        routes.forEach((route) => {
            let keyItem = key ? key + "." + route.name : route.name;
            let pathItem = path ? path + "/" + route.path : route.path;
            let redirectItem = route.redirect;
            if (route.redirect && route.childrens) {
                redirectItem = route.childrens.find(
                    (children) => children.name === route.redirect
                );
                redirectItem = redirectItem.path;
            }
            result.push({
                title: route.title,
                name: keyItem,
                path: pathItem,
                component: route.component,
                redirect: redirectItem,
                private: route.private,
            });
            route.childrens &&
                (result = routerMap(
                    route.childrens,
                    pathItem,
                    result,
                    keyItem
                ));
        });
    return result;
}
export function findNodeBreadcrumb(find, routes) {
    return routes.find((item) => item.name === find);
}
export function getBreadcrumb(search) {
    let result = [],
        routes = Router,
        keyItem = undefined,
        pathItem = undefined;
    search.split(".").forEach((item) => {
        let find = findNodeBreadcrumb(item, routes);
        if (find) {
            keyItem = keyItem ? keyItem + "." + find.name : find.name;
            pathItem = pathItem ? pathItem + "/" + find.path : find.path;
            result.push({
                key: keyItem,
                title: find.title,
                link: "/" + pathItem,
            });
            if (find.childrens) {
                routes = find.childrens;
            }
        }
    });
    return result;
}
export default Router;
