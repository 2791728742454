import { getApi } from "@/app/Api";
import {
    formatBalance,
    formatInteger,
    getAvatar,
    getImage,
    getRoute,
    getStorage,
    handleErrors,
    notifySuccess,
    send,
    setInput,
    setOption,
} from "@/app/Func";
import Input from "@/components/Input";
import Option from "@/components/Option";
import Textarea from "@/components/Textarea";
import { RenderError } from "@/components/Validator";
import Header from "@/layouts/Header";
import React from "react";
import { Navigate } from "react-router";

class Info extends React.Component {
    constructor(props) {
        super(props);
        window.Info = this;
        this.state = {
            submited: false,
            id: getStorage("id"),
            username: getStorage("username"),
            balance: getStorage("balance"),
            point: getStorage("point"),
            image: getStorage("image"),
            bank_id: getStorage("bank_id"),
            bank_number: getStorage("bank_number"),
            bank_user: getStorage("bank_user"),
            info: getStorage("info"),
            name: getStorage("name"),
            check_bank: getStorage("check_bank"),
            banks: [],
            errors: {},
        };
    }
    mounted = false;
    componentDidMount = () => {
        this.mounted = true;
        this.initData();
    };
    componentWillUnmount() {
        this.mounted = false;
    }
    initData = () => {
        this.state.id &&
            send(
                getApi("info", "path"),
                getApi("info", "method"),
                undefined,
                (res) => {
                    if (res.data && res.data.data) {
                        let data = res.data.data,
                            banks = [];
                        Object.keys(data.banks).forEach((key, index) => {
                            banks.push({
                                value: data.banks[key].id,
                                label: data.banks[key].name,
                            });
                        });
                        this.mounted &&
                            this.setState({
                                ...this.state,
                                banks: banks,
                                id: data.user.id,
                                username: data.user.username,
                                balance: data.user.balance,
                                point: data.user.point,
                                image: data.user.image,
                                bank_id: data.user.bank_id,
                                bank_number: data.user.bank_number,
                                bank_user: data.user.bank_user,
                                info: data.user.info,
                                name: data.user.name,
                                check_bank: data.user.check_bank,
                            });
                    }
                },
                (res) => {}
            );
    };
    handleSubmit = (event) => {
        event.preventDefault();
        send(
            getApi("update", "path"),
            getApi("update", "method"),
            {
                bank_id: this.state.bank_id,
                bank_number: this.state.bank_number,
                bank_user: this.state.bank_user,
                name: this.state.name,
                info: this.state.info,
            },
            (res) => {
                if (res.data.code === 200) {
                    res.data.message && notifySuccess(res.data.message);
                    this.mounted &&
                        this.setState({
                            ...this.state,
                            submited: true,
                        });
                } else {
                    handleErrors(res, this);
                }
            },
            (res) => {
                handleErrors(res, this);
            }
        );
    };
    render() {
        return this.state.submited ? (
            <Navigate to={getRoute("home")}></Navigate>
        ) : (
            <div className="info">
                <Header></Header>
                <div className="info__head">
                    <div className="info__head-avatar">
                        <img
                            src={
                                this.state.image
                                    ? getImage(this.state.image)
                                    : getAvatar()
                            }
                            alt=""
                        />
                    </div>
                    <div className="info__head-content">
                        <h2>
                            <span>Tài khoản người dùng</span> <br />
                            {this.state.username ? this.state.username : "..."}
                        </h2>
                        <div className="menu__info">
                            <span>Số dư: </span>
                            <strong>
                                {this.state.balance
                                    ? formatBalance(this.state.balance)
                                    : "..."}
                            </strong>
                            <br />
                            <span>Điểm: </span>
                            <small
                                style={{
                                    color: "var(--hover)",
                                    fontSize: "14px",
                                }}
                            >
                                {this.state.point
                                    ? formatInteger(this.state.point)
                                    : "..."}
                            </small>
                        </div>
                    </div>
                </div>
                <form onSubmit={this.handleSubmit} className="form info__form">
                    {this.state.banks && (
                        <div className="form__item">
                            <div className="form__item-title">
                                Chọn ngân hàng
                                <span className="form__item-required">*</span>
                            </div>
                            <div className="form__item-field">
                                <Option
                                    value={this.state.banks.filter(
                                        ({ value }) =>
                                            value ===
                                            parseInt(this.state.bank_id)
                                    )}
                                    placeholder="Chọn ngân hàng"
                                    name="bank_id"
                                    onChange={(event) =>
                                        setOption(event, this, "bank_id")
                                    }
                                    search={true}
                                    options={this.state.banks}
                                    className={
                                        this.state.errors.bank_id && "error"
                                    }
                                    disabled={this.state.check_bank}
                                    readOnly={this.state.check_bank}
                                ></Option>
                            </div>
                            <RenderError
                                errors={this.state.errors}
                                field="bank_id"
                            ></RenderError>
                        </div>
                    )}
                    <div className="form__item">
                        <div className="form__item-title">
                            Chủ tài khoản
                            <span className="form__item-required">*</span>
                        </div>
                        <div className="form__item-field">
                            <Input
                                format="ALPHABET"
                                max="100"
                                onInput={(event) => setInput(event, this)}
                                autoComplete="new-password"
                                name="bank_user"
                                placeholder="Từ 3 tới 100 ký tự"
                                value={this.state.bank_user}
                                className={
                                    this.state.errors.bank_user && "error"
                                }
                                disabled={this.state.check_bank}
                                readOnly={this.state.check_bank}
                            ></Input>
                        </div>
                        <RenderError
                            errors={this.state.errors}
                            field="bank_user"
                        ></RenderError>
                    </div>
                    <div className="form__item">
                        <div className="form__item-title">
                            Số tài khoản
                            <span className="form__item-required">*</span>
                        </div>
                        <div className="form__item-field">
                            <Input
                                format="Alphadash"
                                max="100"
                                nospace="true"
                                onInput={(event) => setInput(event, this)}
                                autoComplete="new-password"
                                name="bank_number"
                                placeholder="Từ 3 tới 100 ký tự"
                                value={this.state.bank_number}
                                className={
                                    this.state.errors.bank_number && "error"
                                }
                                disabled={this.state.check_bank}
                                readOnly={this.state.check_bank}
                            ></Input>
                        </div>
                        <RenderError
                            errors={this.state.errors}
                            field="bank_number"
                        ></RenderError>
                    </div>
                    <div className="form__item">
                        <div className="form__item-title">
                            Thông tin thêm (Ghi chú)
                        </div>
                        <div className="form__item-field">
                            <Textarea
                                max="1000"
                                onInput={(event) => setInput(event, this)}
                                autoComplete="new-password"
                                name="info"
                                placeholder="Nhập ghi chú"
                                value={this.state.info}
                                className={this.state.errors.info && "error"}
                            ></Textarea>
                        </div>
                        <RenderError
                            errors={this.state.errors}
                            field="info"
                        ></RenderError>
                    </div>
                    <div className="form__item">
                        <button
                            className="btn btn__big btn__success"
                            type="submit"
                            disabled={
                                this.state.bank_id &&
                                this.state.bank_user &&
                                this.state.bank_number &&
                                !this.state.check_bank
                                    ? false
                                    : true
                            }
                            readOnly={
                                this.state.bank_id &&
                                this.state.bank_user &&
                                this.state.bank_number &&
                                !this.state.check_bank
                                    ? false
                                    : true
                            }
                        >
                            Cập nhật thông tin
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default Info;
