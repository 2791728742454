import React from "react";
import withRouter from "@/app/withRouter";
import {
    checkObjectEmpty,
    getParamUrl,
    getRoute,
    handleErrors,
    send,
    setInput,
    syncData,
} from "@/app/Func";
import Validator, { RenderError } from "@/components/Validator";
import Input from "@/components/Input";
import { getApi } from "@/app/Api";
import { trans } from "@/app/Lang";
import { Link, Navigate } from "react-router-dom";

class Register extends React.Component {
    constructor(props) {
        super(props);
        window.Register = this;
        this.state = {
            username: getParamUrl("username") || undefined,
            password: undefined,
            password_confirmation: undefined,
            showPassword: false,
            showPasswordConfirmation: false,
            errors: {},
        };
        this.validator = new Validator(this.rules);
    }
    mounted = false;
    componentDidMount = () => {
        this.mounted = true;
    };
    componentWillUnmount() {
        this.mounted = false;
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.mounted &&
            this.setState(
                {
                    ...this.state,
                    errors: this.validator.valid(this.state),
                },
                () => {
                    if (checkObjectEmpty(this.state.errors)) {
                        send(
                            getApi("register"),
                            getApi("register", "method"),
                            {
                                username: this.state.username,
                                password: this.state.password,
                                password_confirmation:
                                    this.state.password_confirmation,
                            },
                            (res) => {
                                syncData(res.data.data);
                            },
                            (res) => {
                                handleErrors(res, this);
                            }
                        );
                    }
                }
            );
    };
    render() {
        return window.App.state.isLogin ? (
            <Navigate to={getRoute("home")}></Navigate>
        ) : (
            <div className="auth">
                <form className="auth__form" onSubmit={this.handleSubmit}>
                    <div className="auth__form-logo">
                        <img
                            src="/images/c23acc94649051.Y3JvcCwxMTcwLDkxNSwwLDEwNA-removebg-preview.png"
                            alt=""
                        />
                    </div>
                    <div className="auth__form-content">
                        <div className="auth__form-item">
                            <Input
                                format="alphadash"
                                onInput={(event) => setInput(event, this)}
                                name="username"
                                placeholder={trans(
                                    "Họ và tên (*). VD: nguyenvana_123, ..."
                                )}
                                value={this.state.username}
                                className={
                                    this.state.errors.username && "error"
                                }
                            ></Input>
                            <RenderError
                                errors={this.state.errors}
                                field="username"
                            ></RenderError>
                        </div>
                        <div className="auth__form-item">
                            <div className="auth__form-icon">
                                <Input
                                    onInput={(event) => setInput(event, this)}
                                    type={
                                        this.state.showPassword
                                            ? "text"
                                            : "password"
                                    }
                                    name="password"
                                    placeholder={trans("Mật khẩu (*)")}
                                    value={this.state.password}
                                    className={
                                        this.state.errors.password && "error"
                                    }
                                ></Input>
                                <i
                                    onClick={() =>
                                        this.mounted &&
                                        this.setState({
                                            showPassword:
                                                !this.state.showPassword,
                                        })
                                    }
                                    className={
                                        "fas" +
                                        (this.state.showPassword
                                            ? " fa-eye"
                                            : " fa-eye-slash")
                                    }
                                ></i>
                            </div>
                            <RenderError
                                errors={this.state.errors}
                                field="password"
                            ></RenderError>
                        </div>
                        <div className="auth__form-item">
                            <div className="auth__form-icon">
                                <Input
                                    onInput={(event) => setInput(event, this)}
                                    type={
                                        this.state.showPasswordConfirmation
                                            ? "text"
                                            : "password"
                                    }
                                    name="password_confirmation"
                                    placeholder={trans("Nhắc lại mật khẩu (*)")}
                                    value={this.state.password_confirmation}
                                    className={
                                        this.state.errors
                                            .password_confirmation && "error"
                                    }
                                ></Input>
                                <i
                                    onClick={() =>
                                        this.mounted &&
                                        this.setState({
                                            showPasswordConfirmation:
                                                !this.state
                                                    .showPasswordConfirmation,
                                        })
                                    }
                                    className={
                                        "fas" +
                                        (this.state.showPasswordConfirmation
                                            ? " fa-eye"
                                            : " fa-eye-slash")
                                    }
                                ></i>
                            </div>
                            <RenderError
                                errors={this.state.errors}
                                field="password_confirmation"
                            ></RenderError>
                        </div>
                        <div className="auth__form-item">
                            <button
                                type="submit"
                                className="btn btn__big btn__warning auth__form-button"
                            >
                                {trans("Đăng ký")}
                            </button>
                        </div>
                    </div>
                    <div className="auth__form-footer">
                        <span>Đã có tài khoản ?</span>
                        <Link to={getRoute("login")}>👉 Đăng nhập ngay</Link>
                    </div>
                    <div className="auth__form-banner">
                        <img src="images/2.png" alt="" />
                    </div>
                    <div className="auth__form-copyright">
                        Giấy phép mạng xã hội: 157/GP-BTTTT do Bộ Thông tin và
                        Truyền thông cấp ngày 24/4/2019. <br />
                        Đơn vị chủ quản: Công ty Cổ phần VNG
                    </div>
                </form>
            </div>
        );
    }
    rules = [
        {
            field: "username",
            method: "isEmpty",
            validWhen: false,
            message: "Tên đăng nhập không được bỏ trống.",
        },
        {
            field: "username",
            method: "isLength",
            args: [{ min: 3, max: 30 }],
            validWhen: true,
            message: "Tên đăng nhập từ 3 tới 30 ký tự.",
        },
        {
            field: "password",
            method: "isEmpty",
            validWhen: false,
            message: "Mật khẩu không được bỏ trống.",
        },
        {
            field: "password",
            method: "isLength",
            args: [{ min: 6, max: 30 }],
            validWhen: true,
            message: "Mật khẩu từ 6 tới 30 ký tự.",
        },
        {
            field: "password_confirmation",
            method: "isEmpty",
            validWhen: false,
            message: "Nhắc lại mật khẩu không được bỏ trống.",
        },
        {
            field: "password_confirmation",
            method: "isLength",
            args: [{ min: 6, max: 30 }],
            validWhen: true,
            message: "Nhắc lại mật khẩu từ 6 tới 30 ký tự.",
        },
    ];
}

export default withRouter(Register);
