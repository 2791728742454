import { trans } from "@/app/Lang";
import React from "react";

class Page404 extends React.Component {
    constructor(props) {
        super(props);
        window.Page404 = this;
        this.state = {};
    }
    mounted = false;
    componentDidMount = () => {
        this.mounted = true;
    };
    componentWillUnmount() {
        this.mounted = false;
    }
    render() {
        return (
            <div className="page404">
                <img
                    src="/images/404/5.svg"
                    alt={trans("Thiết bị không phù hợp !")}
                />
                <strong>{trans("Thiết bị không phù hợp !")}</strong>
            </div>
        );
    }
}

export default Page404;
