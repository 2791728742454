import Header from "@/layouts/Header";
import React from "react";

class Rule extends React.Component {
    constructor(props) {
        super(props);
        window.Rule = this;
        this.state = {};
    }
    mounted = false;
    componentDidMount = () => {
        this.mounted = true;
    };
    componentWillUnmount() {
        this.mounted = false;
    }
    render() {
        return (
            <div className="rule">
                <Header></Header>
                <div className="title">Quy tắc hoạt động</div>
                <div className="rule__item">
                    Thông qua lượt bỏ phiếu để gia tăng tương tác, tăng lượt
                    follow của ca sĩ trên bảng sếp hạng{" "}
                </div>
                <div className="rule__item">
                    ①: Hãy chọn 1 ca sĩ bạn yêu thích, thông qua việc bỏ phiếu
                    tích điểm bạn sẽ bình chọn cho ca sĩ đó nhầm mục đích gia
                    tăng lượt tương tác và follow của ca sĩ đó trên bảng xếp
                    hạng để ca sĩ bạn yêu thích có thể đứng đầu bảng xếp hạng và
                    nhận được giải thưởng cao nhất
                </div>
                <div className="rule__item">
                    ②: 100k = 1 điểm, ví dụ: nạp 3 triệu nhận 30 điểm tương ứng,
                    30 điểm trên hệ thống bạn sẽ sử dụng nó để bình chọn cho ca
                    sĩ bạn yêu thích sau khi hoàn thành hệ thống Zing MP3 sẽ
                    hoàn trả số tiền 3 triệu đồng cho bạn. Đồng thời bạn sẽ nhận
                    được 1 món quà tương ứng là 900k và phần quà này sẽ được
                    ship đến địa chỉ mà bạn cung cấp cho công ty
                </div>
                <div className="rule__item">
                    ③: Hiện các hoạt động của chúng tôi đang rất phổ biến và
                    nhiều khách hàng đã và đang mong muốn tham gia Nếu không
                    muốn chờ đợi quá lâu hoặc không thích các sản phẩm được
                    tặng, Chúng tôi sẽ quy đổi ra tiền mặt và sẽ chuyển số tiền
                    được quy đổi của mặt hàng đó trực tiếp vào thẻ ngân hàng của
                    bạn.
                </div>
            </div>
        );
    }
}

export default Rule;
