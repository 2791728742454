import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { checkStorage, getRoute, getStorage, setStorage } from "./Func";
import Master from "@/layouts/Master";
import Login from "@/sceens/Login";
import { isMobile } from "react-device-detect";
import { routerMap } from "./Router";
import "../scss/app.scss";
import Page404 from "@/sceens/Page404";

class App extends React.Component {
    constructor(props) {
        super(props);
        window.App = this;
        this.state = {
            isLogin:
                getStorage("token") && !getStorage("expired") ? true : false,
            lang: checkStorage("lang") ? getStorage("lang") : "vi",
            user: {
                id: getStorage("id"),
                username: getStorage("username"),
                balance: getStorage("balance"),
                point: getStorage("point"),
                bank_id: getStorage("bank_id"),
                bank_number: getStorage("bank_number"),
                bank_user: getStorage("bank_user"),
                name: getStorage("name"),
                address: getStorage("address"),
                image: getStorage("image"),
                info: getStorage("info"),
                gender: getStorage("gender"),
                status: getStorage("status"),
                bank_name: getStorage("bank_name"),
                check_bank: getStorage("check_bank"),
                gender_text: getStorage("gender_text"),
                status_text: getStorage("status_text"),
                check_withdraw: getStorage("check_withdraw"),
                created_at: getStorage("created_at"),
                updated_at: getStorage("updated_at"),
            },
        };
        this.routes = routerMap();
    }
    mounted = false;
    componentDidMount = () => {
        this.mounted = true;
        if (!checkStorage("lang")) {
            setStorage({ lang: "vi" });
        }
        this.interval = setInterval(this.check, 1);
    };
    componentWillUnmount = () => {
        this.mounted = false;
        clearInterval(this.interval);
    };
    check = () => {
        if (
            getStorage("token") &&
            Date.parse(getStorage("expired_at")) > Date.now() &&
            !getStorage("expired")
        ) {
            this.state.isLogin !== true &&
                this.mounted &&
                this.setState({
                    ...this.state,
                    isLogin: true,
                });
        } else {
            // localStorage.clear();
            this.state.isLogin !== false &&
                this.mounted &&
                this.setState({
                    ...this.state,
                    isLogin: false,
                });
        }
        return true;
    };
    setLang = (selected) => {
        let lang = selected.value;
        setStorage({ lang: lang });
        this.mounted &&
            this.setState(
                {
                    ...this.state,
                    lang: lang,
                },
                () => window.location.reload()
            );
    };
    render() {
        return isMobile || true ? (
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<Master></Master>}>
                        {this.routes.map((route) => {
                            return (
                                <Route
                                    key={route.name}
                                    path={route.path ? route.path : ""}
                                    element={
                                        route.redirect ? (
                                            <Navigate
                                                replace
                                                to={route.redirect}
                                            ></Navigate>
                                        ) : route.private ? (
                                            this.state.isLogin ? (
                                                <route.component></route.component>
                                            ) : (
                                                <Login></Login>
                                            )
                                        ) : (
                                            <route.component></route.component>
                                        )
                                    }
                                ></Route>
                            );
                        })}
                        <Route
                            path="*"
                            element={
                                <Navigate
                                    replace
                                    to={getRoute("home")}
                                ></Navigate>
                            }
                        ></Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        ) : (
            <Page404></Page404>
        );
    }
}
export default App;
