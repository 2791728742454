import React from "react";
import withRouter from "@/app/withRouter";
import {
    formatBalance,
    formatInteger,
    getAvatar,
    getImage,
    getRoute,
    getRouteTitle,
    getStorage,
    handleErrors,
    send,
} from "@/app/Func";
import { getApi } from "@/app/Api";
import { NavLink } from "react-router-dom";

class Header extends React.Component {
    constructor(props) {
        super(props);
        window.Header = this;
        this.state = {
            menuActive: false,
        };
    }
    mounted = false;
    componentDidMount = () => {
        this.mounted = true;
    };
    componentWillUnmount() {
        this.mounted = false;
    }
    showMenu = () => {
        this.mounted &&
            this.setState({
                ...this.state,
                menuActive: true,
            });
    };
    hideMenu = () => {
        this.mounted &&
            this.setState({
                ...this.state,
                menuActive: false,
            });
    };
    handleLogout = () => {
        send(
            getApi("logout"),
            getApi("logout", "method"),
            undefined,
            (res) => {},
            (res) => {
                handleErrors(res, this);
            }
        );
    };
    render() {
        return (
            <React.Fragment>
                <div className="header">
                    <div className="header__cskh">
                        <i className="fas fa-headset"></i>
                        <div data-id="eeuVJnPCRVl" className="livechat_button">
                            <NavLink to={getRoute("home")}>CSKH</NavLink>
                        </div>
                    </div>
                    <NavLink className="header__logo" to={getRoute("home")}>
                        <img
                            src="/images/c23acc94649051.Y3JvcCwxMTcwLDkxNSwwLDEwNA-removebg-preview.png"
                            alt=""
                        />
                    </NavLink>
                    <div
                        className="header__menu"
                        onClick={() => this.showMenu()}
                    >
                        <i className="fas fa-bars"></i>
                    </div>
                </div>
                <div
                    className={
                        "menu" + (this.state.menuActive ? " menu__active" : "")
                    }
                >
                    <div
                        className="menu__bg"
                        onClick={() => this.hideMenu()}
                    ></div>
                    <div className="menu__main">
                        <div className="menu__head">
                            <div className="menu__logo">
                                <img
                                    src={
                                        getStorage("image")
                                            ? getImage(getStorage("image"))
                                            : getAvatar()
                                    }
                                    alt=""
                                />
                            </div>
                            <h2>
                                {getStorage("username")
                                    ? getStorage("username")
                                    : "..."}
                            </h2>
                            <div className="menu__info">
                                <span>Số dư: </span>
                                <strong>
                                    {getStorage("balance")
                                        ? formatBalance(getStorage("balance"))
                                        : "..."}
                                </strong>
                                <span>Điểm: </span>
                                <small>
                                    {getStorage("point")
                                        ? formatInteger(getStorage("point"))
                                        : "..."}
                                </small>
                            </div>
                        </div>
                        <ul className="menu__body">
                            {/* <li>
                                <NavLink to={getRoute("home")}>
                                    <i className="far fa-home"></i>
                                    <span>{getRouteTitle("home")}</span>
                                </NavLink>
                            </li> */}
                            <li>
                                <NavLink to={getRoute("info")}>
                                    <i className="far fa-user"></i>
                                    <span>{getRouteTitle("info")}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={getRoute("order")}>
                                    <i className="far fa-star-sharp-half-stroke"></i>
                                    <span>{getRouteTitle("order")}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={getRoute("withdraw")}>
                                    <i className="far fa-money-from-bracket"></i>
                                    <span>{getRouteTitle("withdraw")}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={getRoute("withdraw.history")}>
                                    <i className="far fa-money-bill-trend-up"></i>
                                    <span>
                                        {getRouteTitle("withdraw.history")}
                                    </span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={getRoute("rules")}>
                                    <i className="far fa-book-sparkles"></i>
                                    <span>{getRouteTitle("rules")}</span>
                                </NavLink>
                            </li>
                            <li>
                                <a href="https://zingmp3.vn" target="blank">
                                    <i className="far fa-globe"></i>
                                    <span>Trang web chính thức</span>
                                </a>
                            </li>
                            <li>
                                <div onClick={this.handleLogout}>
                                    <i className="far fa-right-from-bracket"></i>
                                    <span>Đăng xuất</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Header);
