import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Cookies from "universal-cookie";
import nprogress from "nprogress";
import axios from "axios";
import moment from "moment";
import "moment/locale/vi";
import { routerMap } from "./Router";

export function getRoute(search, params = undefined) {
    let routes = routerMap(),
        result = routes.find((route) => {
            return route.name === search;
        });
    if (result && result.path) {
        result = "/" + result.path;
        if (params && typeof params === "object") {
            result = injectParams(result, params);
        }
        return result;
    }
    return undefined;
}
export function getRouteTitle(search) {
    let routes = routerMap(),
        result = routes.find((route) => {
            return route.name === search;
        });
    return result && result.title ? result.title : undefined;
}
export function injectParams(path, params) {
    if (params) {
        Object.keys(params).forEach(function (key) {
            let regexp = new RegExp("(:" + key + ")", "g");
            path = path.replace(regexp, params[key]);
        });
    }
    return path;
}
export function getParamUrl(param, url = window.location.search) {
    let params = new URLSearchParams(url);
    return params.get(param);
}
export function getParamRoute(param = undefined, component = undefined) {
    if (
        param &&
        component &&
        component.props &&
        component.props.data &&
        component.props.data.params
    ) {
        return component.props.data.params[param] || undefined;
    }
    return undefined;
}
export function notify(message) {
    return message && toast(message);
}
export function notifySuccess(message) {
    return message && toast.success(message);
}
export function notifyWarning(message) {
    return message && toast.warning(message);
}
export function notifyError(message) {
    return message && toast.error(message);
}
export function notifyInfo(message) {
    return message && toast.info(message);
}
export function pushAlert(
    message,
    icon = "success",
    confirm = false,
    html = false
) {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
        target: "#root",
        icon: icon,
        title: message,
        text: null,
        html: html,
        showCancelButton: true,
        showConfirmButton: confirm,
        confirmButtonColor: "#23a981",
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Đóng",
    });
}
export function confirmAlert(
    message,
    callback = undefined,
    icon = "success",
    html = false
) {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
        target: "#root",
        icon: icon,
        title: message,
        text: undefined,
        html: html,
        showCancelButton: true,
        confirmButtonColor: "#23a981",
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Đóng",
    }).then((confirmed) => {
        callback && callback(confirmed && confirmed.value === true);
    });
}
export function checkStorage(key) {
    return localStorage.hasOwnProperty(key) ? true : false;
}
export function getStorage(key) {
    let result = localStorage.getItem(key);
    result = result === "true" ? true : result === "false" ? false : result;
    result =
        result === "null" ||
        result === "NULL" ||
        result === "Null" ||
        result === "undefined"
            ? undefined
            : result;
    return result;
}
export function setStorage(object, callback = undefined) {
    if (
        typeof object === "object" &&
        !Array.isArray(object) &&
        object !== undefined
    ) {
        Object.keys(object).forEach(function (key) {
            let value =
                object[key] === "true"
                    ? true
                    : object[key] === "false"
                    ? false
                    : object[key];
            value =
                object[key] === null ||
                object[key] === "null" ||
                object[key] === "NULL" ||
                object[key] === "Null" ||
                object[key] === "undefined"
                    ? undefined
                    : object[key];
            localStorage.setItem(key, value);
        });
        callback && callback();
        return true;
    }
    callback && callback();
    return false;
}
export function removeStorage(...items) {
    if (typeof items === "object" && items !== null && items !== undefined) {
        Object.keys(items).forEach(function (key) {
            localStorage.removeItem(items[key]);
        });
        return true;
    }
    return false;
}
export function getCookie(key) {
    const cookies = new Cookies();
    let result = cookies.get(key, { path: "/" });
    result = result === "true" ? true : result === "false" ? false : result;
    return result;
}
export function setCookie(object) {
    if (
        typeof object === "object" &&
        !Array.isArray(object) &&
        object !== null &&
        object !== undefined
    ) {
        const cookies = new Cookies();
        Object.keys(object).forEach(function (key) {
            cookies.get(key) && cookies.remove(key);
            let value =
                object[key] === "true"
                    ? true
                    : object[key] === "false"
                    ? false
                    : object[key];
            cookies.set(key, value, {
                path: "/",
                secure: true,
                sameSite: "None",
            });
        });
        return true;
    }
    return false;
}
export function removeCookie(...items) {
    if (typeof items === "object" && items !== null && items !== undefined) {
        const cookies = new Cookies();
        Object.keys(items).forEach(function (key) {
            cookies.remove(items[key]);
        });
        return true;
    }
    return false;
}
export function env(key) {
    let result = process.env[key];
    return result === "true"
        ? true
        : result === "false"
        ? false
        : typeof result === "string" && result.toLowerCase() === "null"
        ? undefined
        : result === "" ||
          result === undefined ||
          result.toLowerCase() === "undefined"
        ? undefined
        : result;
}
export function copy(value) {
    let temp = document.createElement("input");
    temp.setAttribute("value", value);
    document.body.appendChild(temp);
    temp.select();
    document.execCommand("copy");
    document.body.removeChild(temp);
    notifySuccess("Đã sao chép !");
}
export function removeVN(string) {
    string = string
        ?.toString()
        .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
        .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
        .replace(/ì|í|ị|ỉ|ĩ/g, "i")
        .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
        .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
        .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
        .replace(/đ/g, "d")
        .replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A")
        .replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E")
        .replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I")
        .replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O")
        .replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U")
        .replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y")
        .replace(/Đ/g, "D");
    return string;
}
export function toAlphabet(string, space = true, mode = undefined) {
    let result = removeVN(string);
    switch (mode) {
        case 1:
            result = result.toLowerCase().replace(/[^a-z\s]/g, "");
            break;
        case 2:
            result = result.toUpperCase().replace(/[^A-Z\s]/g, "");
            break;
        default:
            result = result.replace(/[^A-Za-z\s]/g, "");
            break;
    }
    return space ? result.replace(/\s\s+/g, " ") : result.replace(/\s+/g, "");
}
export function toAlphadash(string, space = true, mode = undefined) {
    let result = removeVN(string);
    switch (mode) {
        case 1:
            result = result.toLowerCase().replace(/[^a-z0-9.\s]/g, "");
            break;
        case 2:
            result = result.toUpperCase().replace(/[^A-Z0-9.\s]/g, "");
            break;
        default:
            result = result.replace(/[^A-Za-z0-9.\s]/g, "");
            break;
    }
    return space ? result.replace(/\s\s+/g, " ") : result.replace(/\s+/g, "");
}
export function toAlphabetVN(string, space = true, mode = undefined) {
    let result = string?.toString();
    switch (mode) {
        case 1:
            result = result
                .toLowerCase()
                .replace(
                    /[^a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ\s]/g,
                    ""
                );
            break;
        case 2:
            result = result
                .toUpperCase()
                .replace(
                    /[^A-ZÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ\s]/g,
                    ""
                );
            break;
        default:
            result = result.replace(
                /[^A-Za-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ\s]/g,
                ""
            );
            break;
    }
    return space ? result.replace(/\s\s+/g, " ") : result.replace(/\s+/g, "");
}
export function toAlphadashVN(string, space = true, mode = undefined) {
    let result = string?.toString();
    switch (mode) {
        case 1:
            result = result
                .toLowerCase()
                .replace(
                    /[^a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ0-9.\s]/g,
                    ""
                );
            break;
        case 2:
            result = result
                .toUpperCase()
                .replace(
                    /[^A-ZÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ0-9.\s]/g,
                    ""
                );
            break;
        default:
            result = result.replace(
                /[^A-Za-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ0-9.\s]/g,
                ""
            );
            break;
    }
    return space ? result.replace(/\s\s+/g, " ") : result.replace(/\s+/g, "");
}
export function toSlug(string, mode = undefined) {
    let result = removeVN(string);
    result = result
        .replace(/[^A-Za-z0-9-\s]/g, "")
        .replace(/\s+/g, "-")
        .replace(/--+/g, "-")
        .replace(/^-+|-+$/g, "");
    switch (mode) {
        case 1:
            return result.toLowerCase().replace(/[^a-z0-9-]/g, "");
        case 2:
            return result.toUpperCase().replace(/[^A-Z0-9-]/g, "");
        default:
            return result.replace(/[^A-Za-z0-9-]/g, "");
    }
}
export function toPhoneVN(string, max = 10) {
    return string
        ?.toString()
        .replace(/[^0-9]/g, "")
        .slice(0, max);
}
export function formatDate(dateObject, format = undefined) {
    dateObject = dateObject
        ? moment(dateObject).isValid()
            ? moment(dateObject)
            : moment(dateObject, "YYYY-MM-DD HH:mm:ss", true).isValid()
            ? moment(dateObject, "YYYY-MM-DD HH:mm:ss", true)
            : moment()
        : moment();
    let result = dateObject.locale("vi");
    // result = result.format("ddd, DD \\T\\h\\á\n\\g MM YYYY");
    // result = result.format("ddd, DD/MM/YYYY");
    // result = result.format("ddd, DD/MM/YYYY \\(hh:mm A\\)");
    // result = result.format("YYYY-MM-DD");
    // result = result.format("DD-MM-YYYY \\(hh:mm A\\)");
    // result = result.format("ddd, DD/MM/YYYY | hh:mm A");
    // result = result.format("hh:mm:ss A");
    // result = result.format("hh:mm:ssA");
    // result = result.format("YYYY-MM-DD-HH-mm-ss");
    // result = result.format("DD/MM/YYYY \\l\\ú\\c hh:mm A");
    // result = result.format("ddd, DD/MM/YYYY \\l\\ú\\c hh:mm A");
    // result = result.format("hh:mm A DD/MM/YYYY");
    // result = result.format("hh:mm:ss A DD/MM/YYYY");
    // result = result.format("DD/MM/YYYY hh:mm:ss A");
    // result = result.format("DD/MM/YYYY");
    // result = result.format("HH:mm:ss DD/MM/YYYY");
    // result = result.format("HH:mm:ss");
    result = format
        ? result.format(format)
        : result.format("DD/MM/YYYY HH:mm:ss");
    return result;
}
export function countDecimal(value) {
    if (Math.floor(value) === value) return 0;
    return value?.toString().split(".")[1].length || 0;
}
export function getNumeric(value) {
    return value || value === 0
        ? parseFloat(value?.toString().replace(/[^0-9.-]/g, ""))
        : NaN;
}
export function formatInteger(input) {
    let value =
        input || input === 0 ? input?.toString().replace(/[^0-9.-]/g, "") : NaN;
    if (value) {
        if (/^.*\.0*$/g.test(value)) return input;
        let array = value.match(/^(-?)(\d+)((?:\.\d+)?)$/);
        return array[1] + array[2].replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    }
    return input;
}
export function formatFloat(input, fixed = 2) {
    let value =
        input || input === 0 ? input?.toString().replace(/[^0-9.-]/g, "") : NaN;
    if (value) {
        if (/^.+\.0*$/g.test(value)) return input;
        let valueReal = getNumeric(value);
        let decimal = countDecimal(valueReal);
        let array = valueReal
            .toFixed(decimal > fixed ? fixed : decimal)
            .match(/^(-?)(\d+)((?:\.\d+)?)$/);
        return (
            array[1] +
            array[2].replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") +
            array[3].replace(/^0[^.]+/, "")
        );
    }
    return input;
}
export function formatBalance(value, fixed = 0) {
    if (value) {
        return formatFloat(value, fixed) + "đ";
    } else {
        return "0đ";
    }
}
export function formatBalanceShort(value, fixed = 2) {
    value = getNumeric(value);
    if (value || value === 0) {
        let unit = undefined;
        let decimal = countDecimal(value);
        if (value >= 1000000000 || value <= -1000000000) {
            value = parseFloat(value / 1000000000);
            unit = "B"; // Tỷ (Billion) 10^9
        } else if (
            (value < 1000000000 && value >= 1000000) ||
            (value > -1000000000 && value <= -1000000)
        ) {
            value = parseFloat(value / 1000000);
            unit = "M"; // Triệu (Million) 10^6
        } else if (
            (value < 1000000 && value >= 1000) ||
            (value > -1000000 && value <= -1000)
        ) {
            value = parseFloat(value / 1000);
            unit = "K"; // Ngàn 10^3
        } else {
            return formatBalance(value);
        }
        return formatFloat(value, decimal > fixed ? fixed : decimal) + unit;
    } else {
        return undefined;
    }
}
export function getImage(path = undefined) {
    let prefix =
        env("REACT_APP_MODE_PRODUCTION") === true
            ? env("REACT_APP_API_PRODUCTION_URL")
            : env("REACT_APP_API_LOCAL_URL");
    return path ? prefix + path : "/images/defaults/default.jpg";
}
export function stripHtml(html) {
    let element = document.createElement("div");
    element.innerHTML = html;
    return element.textContent || element.innerText || "";
}
export function setInput(event, component) {
    let fieldName =
            event && event.target && event.target.name
                ? event.target.name
                : undefined,
        fieldValue =
            event && event.target && event.target.value
                ? event.target.value
                : undefined;
    if (fieldName) {
        component.mounted &&
            component.setState({
                ...component.state,
                [fieldName]: fieldValue,
                errors: {
                    ...component.state.errors,
                    [fieldName]: undefined,
                },
            });
        return fieldValue;
    }
    return fieldValue;
}
export function setOption(selected, component, field = undefined) {
    if (field) {
        component.mounted &&
            component.setState({
                ...component.state,
                [field]:
                    selected && selected.value ? selected.value : undefined,
                errors: {
                    ...component.state.errors,
                    [field]: "",
                },
            });
        return selected && selected.value ? selected.value : undefined;
    }
    return selected && selected.value ? selected.value : undefined;
}
export function setDate(value, component, field = undefined) {
    if (field) {
        component.mounted &&
            component.setState({
                ...component.state,
                [field]: value,
                errors: {
                    ...component.state.errors,
                    [field]: "",
                },
            });
        return true;
    }
    return false;
}
export function setDateRange(
    values,
    component,
    fieldStart = undefined,
    fieldEnd = undefined
) {
    if (fieldStart && fieldEnd) {
        const [start, end] = values;
        component.mounted &&
            component.setState({
                ...component.state,
                [fieldStart]: start,
                [fieldEnd]: end,
                errors: {
                    ...component.state.errors,
                    [fieldStart]: "",
                    [fieldEnd]: "",
                },
            });
        return true;
    }
    return false;
}
export function getAvatar() {
    const avatars = [
        "/images/users/car (1).jpg",
        "/images/users/car (2).jpg",
        "/images/users/car (3).jpg",
        "/images/users/car (4).jpg",
        "/images/users/car (5).jpg",
        "/images/users/car (6).jpg",
        "/images/users/car (7).jpg",
        "/images/users/car (8).jpg",
        "/images/users/car (9).jpg",
        "/images/users/car (10).jpg",
        "/images/users/car (11).jpg",
        "/images/users/car (12).jpg",
        "/images/users/car (13).jpg",
        "/images/users/car (14).jpg",
        "/images/users/car (15).jpg",
        "/images/users/car (16).jpg",
        "/images/users/car (17).jpg",
        "/images/users/car (18).jpg",
        "/images/users/car (19).jpg",
        "/images/users/car (20).jpg",
        "/images/users/car (21).jpg",
        "/images/users/car (22).jpg",
        "/images/users/car (23).jpg",
        "/images/users/car (24).jpg",
        "/images/users/car (25).jpg",
        "/images/users/car (26).jpg",
        "/images/users/car (27).jpg",
        "/images/users/car (28).jpg",
        "/images/users/car (29).jpg",
        "/images/users/car (30).jpg",
        "/images/users/car (31).jpg",
        "/images/users/car (32).jpg",
        "/images/users/car (33).jpg",
        "/images/users/car (34).jpg",
        "/images/users/car (35).jpg",
        "/images/users/car (36).jpg",
        "/images/users/car (37).jpg",
        "/images/users/car (38).jpg",
        "/images/users/car (39).jpg",
        "/images/users/car (40).jpg",
        "/images/users/car (41).jpg",
        "/images/users/car (42).jpg",
        "/images/users/car (43).jpg",
        "/images/users/car (44).jpg",
        "/images/users/car (45).jpg",
        "/images/users/car (46).jpg",
        "/images/users/car (47).jpg",
        "/images/users/car (48).jpg",
        "/images/users/car (49).jpg",
        "/images/users/car (50).jpg",
        "/images/users/car (51).jpg",
        "/images/users/car (52).jpg",
        "/images/users/car (53).jpg",
        "/images/users/car (54).jpg",
        "/images/users/car (55).jpg",
        "/images/users/car (56).jpg",
        "/images/users/car (57).jpg",
        "/images/users/car (58).jpg",
        "/images/users/car (59).jpg",
        "/images/users/car (60).jpg",
        "/images/users/car (61).jpg",
        "/images/users/car (62).jpg",
        "/images/users/car (63).jpg",
        "/images/users/car (64).jpg",
        "/images/users/car (65).jpg",
        "/images/users/car (66).jpg",
        "/images/users/car (67).jpg",
        "/images/users/car (68).jpg",
    ];
    let avatar = getStorage("avatar");
    if (avatar) {
        return avatar;
    } else {
        let avatar = avatars[Math.floor(Math.random() * avatars.length)];
        setStorage({
            avatar: avatar,
        });
        return avatar;
    }
}
export function randomNumber(min, max, decimalPlaces = 0) {
    var rand = Math.random() * (max - min) + min;
    var power = Math.pow(10, decimalPlaces);
    return Math.floor(rand * power) / power;
}
export function ranBalance() {
    let balance = parseInt(randomNumber(50000, 1500000, 0) / 100) * 100;
    return balance && balance > 0 ? formatBalance(balance) : undefined;
}
export function randomDate(start, end) {
    return formatDate(
        new Date(
            start.getTime() + Math.random() * (end.getTime() - start.getTime())
        )
    );
}
export function hideCharacter(value) {
    if (value) {
        value = value?.toString();
        if (value.length > 8) {
            return (
                value.slice(0, 3) + value.slice(3).replace(/.(?=....)/g, "*")
            );
        } else if (value.length <= 8 && value.length > 5) {
            return value.slice(0, 2) + value.slice(2).replace(/.(?=..)/g, "*");
        } else if (value.length <= 5 && value.length >= 3) {
            return value.slice(0, 1) + value.slice(1).replace(/./g, "*");
        } else {
            return value.replace(/./g, "*");
        }
    }
    return undefined;
}
export function checkObjectEmpty(object) {
    return (
        object &&
        Object.keys(object).length === 0 &&
        Object.getPrototypeOf(object) === Object.prototype
    );
}
export function syncData(data) {
    return data
        ? setStorage({
              id:
                  data.hasOwnProperty("user") && data.user.hasOwnProperty("id")
                      ? data.user.id
                      : getStorage("id"),
              username:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("username")
                      ? data.user.username
                      : getStorage("username"),
              balance:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("balance")
                      ? data.user.balance
                      : getStorage("balance"),
              point:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("point")
                      ? data.user.point
                      : getStorage("point"),
              bank_id:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("bank_id")
                      ? data.user.bank_id
                      : getStorage("bank_id"),
              bank_number:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("bank_number")
                      ? data.user.bank_number
                      : getStorage("bank_number"),
              bank_user:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("bank_user")
                      ? data.user.bank_user
                      : getStorage("bank_user"),
              name:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("name")
                      ? data.user.name
                      : getStorage("name"),
              address:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("address")
                      ? data.user.address
                      : getStorage("address"),
              image:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("image")
                      ? data.user.image
                      : getStorage("image"),
              info:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("info")
                      ? data.user.info
                      : getStorage("info"),
              gender:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("gender")
                      ? data.user.gender
                      : getStorage("gender"),
              status:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("status")
                      ? data.user.status
                      : getStorage("status"),
              bank_name:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("bank_name")
                      ? data.user.bank_name
                      : getStorage("bank_name"),
              check_bank:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("check_bank")
                      ? data.user.check_bank
                      : getStorage("check_bank"),
              gender_text:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("gender_text")
                      ? data.user.gender_text
                      : getStorage("gender_text"),
              status_text:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("status_text")
                      ? data.user.status_text
                      : getStorage("status_text"),
              check_withdraw:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("check_withdraw")
                      ? data.user.check_withdraw
                      : getStorage("check_withdraw"),
              created_at:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("created_at")
                      ? data.user.created_at
                      : getStorage("created_at"),
              updated_at:
                  data.hasOwnProperty("user") &&
                  data.user.hasOwnProperty("updated_at")
                      ? data.user.updated_at
                      : getStorage("updated_at"),
          })
        : false;
}
export function send(
    url,
    method = "GET",
    data = undefined,
    thenCallback,
    catchCallback,
    sending = true,
    contentType = "application/json"
) {
    if (sending) {
        setStorage({ sending: true });
        nprogress.done();
        nprogress.start();
    }
    if (method !== "GET" && method !== "POST") {
        if (data instanceof FormData) {
            data.append("_method", method);
        } else if (data instanceof Object) {
            data._method = method;
        } else if (data === undefined) {
            data = {
                _method: method,
            };
        }
    }
    axios
        .request({
            url: url,
            method: method === "GET" ? "GET" : "POST",
            data: data,
            params: method === "GET" ? data : undefined,
            headers: {
                "Content-Type": contentType,
                token: getStorage("token") || undefined,
                lang: getStorage("lang") || "vi",
            },
            json: contentType === "application/json" ? true : false,
        })
        .then((res) => {
            if (sending) {
                setStorage({
                    sending: false,
                });
                nprogress.done();
            }
            if (res.data.data) {
                let data = res.data.data;
                setStorage({
                    token: data.hasOwnProperty("token")
                        ? data.token
                        : getStorage("token"),
                    expired: data.hasOwnProperty("expired")
                        ? data.expired
                        : getStorage("expired"),
                    expired_at: data.hasOwnProperty("expired_at")
                        ? data.expired_at
                        : getStorage("expired_at"),
                });
                syncData(data);
            }
            thenCallback(res);
        })
        .catch((res) => {
            let response =
                res.response && res.response.data
                    ? res.response.data
                    : undefined;
            if (sending) {
                setStorage({
                    sending: false,
                });
                nprogress.done();
            }
            if (
                response.code &&
                response.code === 403 &&
                response.status === "Forbidden"
            ) {
                response.data &&
                    setStorage({
                        permissions: response.data,
                    });
                console.clear();
            }
            if (
                response.code &&
                response.code === 401 &&
                response.status === "Unauthorized"
            ) {
                localStorage.clear();
                console.clear();
            }
            catchCallback(res);
        });
}
export function handleErrors(res, component) {
    if (res.response && res.response.data) {
        if (res.response.data.errors) {
            component.mounted &&
                component.setState({
                    ...component.state,
                    errors: res.response.data.errors,
                });
            let errors = res.response.data.errors;
            Object.keys(errors).forEach(function (key) {
                if (errors[key] instanceof String) {
                    notifyError(errors[key]);
                } else if (
                    typeof errors[key] === "object" &&
                    errors[key] !== null
                ) {
                    errors[key].forEach(function (error) {
                        notifyError(error);
                    });
                }
            });
        } else if (res.response.data.message) {
            (typeof res.response.data.message === "string" ||
                res.response.data.message instanceof String) &&
                notifyError(res.response.data.message);
        }
    } else if (res.data && res.data.message) {
        (typeof res.data.message === "string" ||
            res.data.message instanceof String) &&
            notifyWarning(res.data.message);
    } else if (res.message) {
        (typeof res.message === "string" || res.message instanceof String) &&
            notifyError(res.message);
    }
}
export function checkPermission(permission) {
    if (permission === true) {
        return true;
    }
    let permissions = getStorage("permissions");
    permissions =
        permissions && permissions.length ? permissions.split(",") : undefined;
    if (
        permission &&
        permissions &&
        permissions instanceof Array &&
        permissions.includes(permission)
    ) {
        return true;
    }
    return false;
}
export function checkSidebar() {
    let check = getStorage("sidebar");
    if (check && check === true) {
        return true;
    }
    return false;
}
export function collapseSidebar() {
    let main = document.getElementById("root");
    if (checkSidebar()) {
        setStorage({ sidebar: false });
        main.classList.remove("root__full");
    } else {
        setStorage({ sidebar: true });
        main.classList.add("root__full");
    }
}
export function handleSidebar() {
    let sidebar_bg = document.getElementById("sidebar__bg");
    if (sidebar_bg) {
        let checkDisplay = window
            .getComputedStyle(sidebar_bg)
            .getPropertyValue("display");
        checkDisplay !== "none" && collapseSidebar();
    }
}
export function filterChildren(children = undefined) {
    let f1 = [],
        f2 = [],
        f3 = [];
    if (children && children instanceof Array) {
        children.forEach((item) => {
            if (item.children) {
                item.children.forEach((item1) => {
                    if (item1.children) {
                        item1.children.forEach((item2) => {
                            f3.push({
                                id: item2.id,
                                username: item2.username,
                                vip: item2.vip,
                                balance: formatBalanceShort(item2.balance),
                            });
                        });
                    }
                    f2.push({
                        id: item1.id,
                        username: item1.username,
                        vip: item1.vip,
                        balance: formatBalanceShort(item1.balance),
                    });
                });
            }
            f1.push({
                id: item.id,
                username: item.username,
                vip: item.vip,
                balance: formatBalanceShort(item.balance),
            });
        });
        return {
            f1: f1.length ? f1 : undefined,
            f2: f2.length ? f2 : undefined,
            f3: f3.length ? f3 : undefined,
        };
    }
    return null;
}
