import { env } from "@/app/Func";
import React from "react";
import withRouter from "@/app/withRouter";

class Socket extends React.Component {
    constructor(props) {
        super(props);
        window.Socket = this;
        this.state = {};
        this.socket = window.Master.socket;
        this.timeOutStorage = undefined;
    }
    mounted = false;
    setStorage = (data) => {
        if (data) {
            console.log(data);
        }
        return;
    };
    componentDidMount() {
        this.mounted = true;
        if (env("REACT_APP_SOCKET_OPEN") === true && window.Master) {
            this.socket.on(env("REACT_APP_NAME"), (result) => {
                let event = result.event,
                    data = result.data;
                switch (event) {
                    default:
                        console.log(data);
                        break;
                }
            });
        }
    }
    componentWillUnmount = () => {
        this.mounted = false;
        if (env("REACT_APP_SOCKET_OPEN") === true && window.Master) {
            this.timeOutStorage && clearTimeout(this.timeOutStorage);
            this.socket.removeAllListeners();
        }
    };
    render() {
        return <React.Fragment></React.Fragment>;
    }
}

export default withRouter(Socket);
